import { FormattedMessage, useIntl } from '@alltrails/shared/react-intl';
import Select from '@alltrails/shared/denali/components/Select';
import { getAvailablePlans } from 'api/Purchases';
import CountryCode from 'types/CountryCode';
import { COLOR_TEXT_ERROR, COLOR_TEXT_PLACEHOLDER, COLOR_TEXT_PRIMARY, FONT_FAMILY_PRIMARY, FONT_SIZE_200 } from '@alltrails/shared/denali/tokens';
import { CardElement, CardElementChangeEvent, CardElementState } from '@recurly/react-recurly';
import { useState } from 'react';
import OutlinedTextField from '@alltrails/shared/components/OutlinedTextField';
import classNames from 'classnames';
import * as styles from './styles/styles.module.scss';
import { getBillingNames } from './utils';
import { CARD_RECURLY_IDs, CARD_VALIDATION_NAMES, TokenValidationErrors } from './types';

type Props = {
  billingCountry?: {
    onChange: (billingCountry: CountryCode, response: Awaited<ReturnType<typeof getAvailablePlans>>) => void;
    value: string;
  };
  isCouponInputExpanded?: boolean;
  isCouponInputShown?: boolean;
  toggleCouponCode?: () => void;
  validationErrors: TokenValidationErrors;
  handleInputChange: (
    inputName: string,
    value: string | { number: CardElementState; expiry: CardElementState; cvv: CardElementState } | CardElementChangeEvent
  ) => void;
  initialFullName?: string;
};

function TokenizedCreditCardForm({
  billingCountry,
  toggleCouponCode,
  isCouponInputExpanded = false,
  isCouponInputShown = false,
  handleInputChange,
  validationErrors,
  initialFullName
}: Props) {
  const { nameError, postalCodeError, ccInputStateError } = validationErrors;

  const [fullName, setFullName] = useState(initialFullName || '');
  const [postalCode, setPostalCode] = useState('');
  const { firstName, lastName } = getBillingNames(fullName);

  const intl = useIntl();
  const countryOptions = [
    { label: intl.formatMessage({ defaultMessage: 'Austria' }), value: 'AT' },
    { label: intl.formatMessage({ defaultMessage: 'Belgium' }), value: 'BE' },
    { label: intl.formatMessage({ defaultMessage: 'Bulgaria' }), value: 'BG' },
    { label: intl.formatMessage({ defaultMessage: 'Croatia' }), value: 'HR' },
    { label: intl.formatMessage({ defaultMessage: 'Republic of Cyprus' }), value: 'CY' },
    { label: intl.formatMessage({ defaultMessage: 'Czech Republic' }), value: 'CZ' },
    { label: intl.formatMessage({ defaultMessage: 'Denmark' }), value: 'DK' },
    { label: intl.formatMessage({ defaultMessage: 'Estonia' }), value: 'EE' },
    { label: intl.formatMessage({ defaultMessage: 'Finland' }), value: 'FI' },
    { label: intl.formatMessage({ defaultMessage: 'France' }), value: 'FR' },
    { label: intl.formatMessage({ defaultMessage: 'Germany' }), value: 'DE' },
    { label: intl.formatMessage({ defaultMessage: 'Greece' }), value: 'GR' },
    { label: intl.formatMessage({ defaultMessage: 'Hungary' }), value: 'HU' },
    { label: intl.formatMessage({ defaultMessage: 'Ireland' }), value: 'IE' },
    { label: intl.formatMessage({ defaultMessage: 'Italy' }), value: 'IT' },
    { label: intl.formatMessage({ defaultMessage: 'Latvia' }), value: 'LV' },
    { label: intl.formatMessage({ defaultMessage: 'Lithuania' }), value: 'LT' },
    { label: intl.formatMessage({ defaultMessage: 'Luxembourg' }), value: 'LU' },
    { label: intl.formatMessage({ defaultMessage: 'Malta' }), value: 'MT' },
    { label: intl.formatMessage({ defaultMessage: 'Netherlands' }), value: 'NL' },
    { label: intl.formatMessage({ defaultMessage: 'Poland' }), value: 'PL' },
    { label: intl.formatMessage({ defaultMessage: 'Portugal' }), value: 'PT' },
    { label: intl.formatMessage({ defaultMessage: 'Romania' }), value: 'RO' },
    { label: intl.formatMessage({ defaultMessage: 'Slovakia' }), value: 'SK' },
    { label: intl.formatMessage({ defaultMessage: 'Slovenia' }), value: 'SI' },
    { label: intl.formatMessage({ defaultMessage: 'Spain' }), value: 'ES' },
    { label: intl.formatMessage({ defaultMessage: 'Sweden' }), value: 'SE' }
  ];

  const showBillingCountry = billingCountry && countryOptions.some(option => billingCountry.value === option.value);

  const selectLabel = intl.formatMessage({ defaultMessage: 'Billing country' });

  const cardElementStyles = {
    fontSize: `${FONT_SIZE_200}px`,
    fontColor: COLOR_TEXT_PRIMARY,
    fontFamily: FONT_FAMILY_PRIMARY,
    placeholder: {
      // placeholder styles can't be controlled via CSS since they're inside an iframe.
      color: `${ccInputStateError ? COLOR_TEXT_ERROR : COLOR_TEXT_PLACEHOLDER} !important`,
      content: {
        number: intl.formatMessage({ defaultMessage: 'Card number' }),
        cvv: 'CVV'
      }
    },
    invalid: {
      fontColor: COLOR_TEXT_ERROR
    }
  };

  return (
    <div>
      <div className={styles.formInputContainer}>
        <div>
          <CardElement
            // Manually apply the error styles to the card element container.
            // Recurly does this automatically onChange, but we also want to apply these styles when the form is submitted with an empty input.
            className={classNames('recurly-card-element-container', { invalid: ccInputStateError })}
            style={cardElementStyles}
            onChange={e => handleInputChange(CARD_VALIDATION_NAMES.CREDIT_CARD, e)}
          />
          {ccInputStateError && <p className={styles.errorMessage}>{ccInputStateError}</p>}
        </div>
        <div className={styles.namesContainer}>
          <OutlinedTextField
            autocomplete="cc-name"
            className={classNames(styles.formInput, { [styles.invalidInput]: !!nameError })}
            changeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
              setFullName(e.target.value);
              handleInputChange(CARD_VALIDATION_NAMES.NAME, e.target.value);
            }}
            label={<FormattedMessage defaultMessage="Name on card" />}
            name="name"
            errorMessage={nameError}
            value={fullName}
          />
        </div>
        <div>
          <OutlinedTextField
            recurlyDataId={CARD_RECURLY_IDs.POSTAL_CODE}
            className={classNames(styles.formInput, { [styles.invalidInput]: !!postalCodeError })}
            autocomplete="postal-code"
            changeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
              setPostalCode(e.target.value);
              handleInputChange(CARD_VALIDATION_NAMES.POSTAL_CODE, e.target.value);
            }}
            errorMessage={postalCodeError}
            label={<FormattedMessage defaultMessage="Postal code" />}
            name="postal code"
          />
        </div>

        {/* Hidden inputs accessed by Recurly */}
        <input type="hidden" name="recurly-token" data-recurly="token" />
        <input type="hidden" name="first-name" data-recurly={CARD_RECURLY_IDs.FIRST_NAME} value={firstName} />
        <input type="hidden" name="last-name" data-recurly={CARD_RECURLY_IDs.LAST_NAME} value={lastName} />
        <input type="hidden" name="postal-code" data-recurly={CARD_RECURLY_IDs.POSTAL_CODE} value={postalCode} />
        <input type="hidden" name="country" data-recurly={CARD_RECURLY_IDs.COUNTRY} value={billingCountry?.value || __AT_DATA__.ipCountry} />
      </div>

      {isCouponInputShown && (
        <div className={styles.couponCol}>
          <button className={styles.couponButton} onClick={toggleCouponCode} type="button">
            {isCouponInputExpanded ? (
              <FormattedMessage defaultMessage="Hide coupon code" />
            ) : (
              <FormattedMessage defaultMessage="Enter a coupon code" />
            )}
          </button>
        </div>
      )}

      {showBillingCountry && (
        <Select
          className={styles.country}
          labelText={selectLabel}
          onChange={async e => {
            const response = await getAvailablePlans(e as CountryCode);
            billingCountry.onChange(e as CountryCode, response);
          }}
          options={countryOptions}
          placeholder={selectLabel}
          testId="billing-country"
          value={billingCountry.value}
        />
      )}
    </div>
  );
}

export default TokenizedCreditCardForm;
