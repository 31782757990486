import { Route as ReactRouterRoute, Switch, Redirect, useHistory } from '@alltrails/shared/components/ReactRouter';
import useLanguageRegionCode from '@alltrails/shared/hooks/useLanguageRegionCode';
import { hasFormErrors } from 'utils/GiftFlowHelper';
import { wrapUrlSafe } from '@alltrails/shared/utils/languageSupport';
import { pushHistory } from 'utils/client_side_routing_helpers';
import CurrencyCode from 'types/CurrencyCode';
import Plan from 'types/Plan';
import useCountryId from 'hooks/useCountryId';
import type { User } from 'types/User';
import { useDispatch } from 'react-redux';
import useUser from 'hooks/useUser';
import LoginForm from 'components/LoginForm';
import SignUpForm from 'components/SignUpForm';
import LoginSignupLayout from 'components/LoginSignupLayout';
import { ComponentProps, PropsWithChildren, useState } from 'react';
import { useGiftFlowContext } from 'pages/Gift/giftContext';
import useIpCountryCode from 'hooks/useIpCountryCode';
import GiftCheckout from 'components/GiftCheckout';
import { RecurlyProvider, Elements } from '@recurly/react-recurly';
import CustomizeGiftForm from '../CustomizeGiftForm';
import GiftLandingPage from '../Landing/GiftLandingPage';
import GiftCardPrint from '../GiftCardPrint';
import GiftReview from '../GiftReview';
import { setUser } from '../../../userSlice';

type Props = {
  currencyCode: CurrencyCode;
  plan: Plan;
  shopifyProducts?: ComponentProps<typeof GiftReview>['shopifyProducts'];
};

function Route({ children, exact, path }: PropsWithChildren<{ exact?: boolean; path: string }>) {
  return (
    <ReactRouterRoute exact={exact} path={path}>
      {children}
    </ReactRouterRoute>
  );
}

const GiftFlowRouting = ({ currencyCode: initialCurrencyCode, plan, shopifyProducts }: Props) => {
  const dispatch = useDispatch();
  const languageRegionCode = useLanguageRegionCode();
  const countryId = useCountryId();
  const user = useUser();
  const [currencyCode, setCurrencyCode] = useState(initialCurrencyCode);
  const [billingCountry, setBillingCountry] = useState(useIpCountryCode());

  const {
    giftFlowState: { giftCustomizeParams, purchasedGiftCard }
  } = useGiftFlowContext();

  const history = useHistory();

  const handleAuthSuccess = ({ user }: { user: User }) => {
    dispatch(setUser(user));
    pushHistory('/gift/payment', history, languageRegionCode);
  };

  return (
    <Switch>
      <Route path="*/gift" exact>
        <GiftLandingPage currencyCode={currencyCode} plan={plan} />
      </Route>
      <Route path="*/gift/customize" exact>
        <CustomizeGiftForm />
      </Route>
      <Route path="*/login" exact>
        {user ? (
          <Redirect to={wrapUrlSafe('/gift', languageRegionCode)} />
        ) : (
          <LoginSignupLayout>
            <LoginForm handleLoginSuccess={handleAuthSuccess} useReactRouter />
          </LoginSignupLayout>
        )}
      </Route>
      <Route path="*/signup" exact>
        {user ? (
          <Redirect to={wrapUrlSafe('/gift', languageRegionCode)} />
        ) : (
          <LoginSignupLayout>
            <SignUpForm handleRegistrationSuccess={handleAuthSuccess} useReactRouter />
          </LoginSignupLayout>
        )}
      </Route>
      <Route path="*/gift/payment" exact>
        {hasFormErrors(giftCustomizeParams) ? (
          <Redirect to={wrapUrlSafe('/gift/customize', languageRegionCode)} />
        ) : (
          <RecurlyProvider publicKey={__AT_DATA__.recurlyPublicKey}>
            <Elements>
              <GiftCheckout
                billingCountry={billingCountry}
                currencyCode={currencyCode}
                plan={plan}
                setBillingCountry={setBillingCountry}
                setCurrencyCode={setCurrencyCode}
              />
            </Elements>
          </RecurlyProvider>
        )}
      </Route>
      <Route path="*/gift/review" exact>
        {!purchasedGiftCard ? (
          <Redirect to={wrapUrlSafe('/gift', languageRegionCode)} />
        ) : (
          <GiftReview {...purchasedGiftCard} shopifyProducts={shopifyProducts} showShopifyPreview={countryId === 313} />
        )}
      </Route>
      <Route path="*/gift/preview" exact>
        {!purchasedGiftCard ? <Redirect to={wrapUrlSafe('/gift', languageRegionCode)} /> : <GiftCardPrint giftSubscription={purchasedGiftCard} />}
      </Route>
      <Route path="*/gift*" exact>
        <Redirect to={wrapUrlSafe('/gift', languageRegionCode)} />
      </Route>
    </Switch>
  );
};

GiftFlowRouting.defaultProps = {
  shopifyProducts: []
};

export default GiftFlowRouting;
