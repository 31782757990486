import { FormEvent, useState } from 'react';
import { defineMessages, FormattedMessage } from '@alltrails/shared/react-intl';
import Button from '@alltrails/shared/denali/components/Button';
import Link from '@alltrails/shared/denali/components/Link';
import OutlinedTextField from '@alltrails/shared/components/OutlinedTextField';
import useFormatMessage from '@alltrails/shared/hooks/useFormatMessage';
import CurrencyCode from 'types/CurrencyCode';
import getHelpCenterUrl from '@alltrails/shared/utils/constants/helpCenterUrl';
import useLanguageRegionCode from '@alltrails/shared/hooks/useLanguageRegionCode';
import { post } from '@alltrails/shared/api';
import * as styles from './styles/styles.module.scss';

const messages = defineMessages({
  ERR_TRY_AGAIN: { defaultMessage: 'Error, please try again' },
  ONCE_YOU_REDEEM: {
    defaultMessage:
      'Once you redeem the gift subscription, your account will be updated and you can start enjoying the benefits of AllTrails+. Your subscription begins on the redemption date and will be active for the length of the plan that was purchased.'
  },
  REDEEM: { defaultMessage: 'Redeem' },
  REDEMPTION_CODE: { defaultMessage: 'Redemption code' }
});

type RedemptionFormProps = {
  couponCode?: string;
  currencyCode?: CurrencyCode;
};

const RedemptionForm = ({ couponCode = null, currencyCode = 'USD' }: RedemptionFormProps) => {
  const {
    formattedDefaultMessages: { ERR_TRY_AGAIN, ONCE_YOU_REDEEM, REDEEM, REDEMPTION_CODE }
  } = useFormatMessage(messages);
  const [code, setCode] = useState(couponCode || '');
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [mobileSubActiveError, setMobileSubActiveError] = useState(false);
  const languageRegionCode = useLanguageRegionCode();

  const onChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (errorMsg) {
      setErrorMsg(null);
    }

    if (mobileSubActiveError) {
      setMobileSubActiveError(false);
    }

    const { value } = e.target;
    setCode(value);
  };

  const redeemGiftCode = () =>
    post(`/api/alltrails/gift/${code}/redeem`, {
      gift_id: code,
      currency: currencyCode
    });

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!code) {
      setErrorMsg(ERR_TRY_AGAIN);
      return;
    }
    setLoading(true);

    try {
      await redeemGiftCode();
      window.location.href = '/plus/welcome';
    } catch (err) {
      const errorData = err?.data?.errors?.[0];
      if (errorData && errorData?.code === 'gift_mobile_sub_active') {
        setMobileSubActiveError(true);
      } else {
        const message = errorData?.message || ERR_TRY_AGAIN;
        setErrorMsg(message);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <section>
      <form id="redemption-form" onSubmit={onSubmit} className={styles.formContainer}>
        <OutlinedTextField type="text" changeHandler={onChange} value={code} label={REDEMPTION_CODE} errorMessage={errorMsg} />
        {mobileSubActiveError && (
          <div className={styles.giftMobileErrorStyles}>
            <FormattedMessage
              defaultMessage="Your account has a current mobile subscription. Please read our <link>FAQ</link> to learn how to redeem your gift."
              values={{
                link: (str: string) => (
                  <Link href={getHelpCenterUrl(languageRegionCode, 360052407531)} target="_blank" size="md" testId="mobile-gift-faq">
                    {str}
                  </Link>
                )
              }}
            />
          </div>
        )}
        <div className={styles.formButtonContainer}>
          <Button
            disabled={!!errorMsg}
            loading={loading}
            testId="redemption-form-redeem"
            text={REDEEM}
            type="submit"
            variant="primary"
            width="full"
          />
        </div>
      </form>
      <div className={styles.formSubText}>{ONCE_YOU_REDEEM}</div>
    </section>
  );
};

export default RedemptionForm;
