import { FormattedMessage, useIntl } from '@alltrails/shared/react-intl';
import * as styles from './OnePercentForThePlanet.module.scss';
import onePercentForThePlanetLogoSrc from './one-percent-logo.svg';

export default function OnePercentForThePlanet() {
  const intl = useIntl();

  return (
    <section className={styles.container}>
      <img alt={intl.formatMessage({ defaultMessage: '1% for the Planet' })} src={onePercentForThePlanetLogoSrc} />
      <div className={styles.text}>
        <FormattedMessage defaultMessage="As a proud member of 1% for the Planet, we give 1% of our annual sales to nonprofit organizations focusing on protecting the wild places we cherish and creating positive change for a healthier planet." />
      </div>
    </section>
  );
}
