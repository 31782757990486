import { FormattedMessage, useIntl } from '@alltrails/shared/react-intl';
import Typography from '@alltrails/shared/denali/components/Typography';
import * as styles from './GiftValuePanel.module.scss';
import giftCard1Src from './GiftCard1.svg';
import perfectAvifSrc from './perfect.avif';
import perfectWebpSrc from './perfect.webp';
import perfectJpgSrc from './perfect.jpg';

// https://www.figma.com/file/gZaqAej8HzoFkyrjlQ4cNs/Integrating-Brand-Changes?node-id=586%3A30927

const blurbs = [
  {
    heading: <FormattedMessage defaultMessage="Quick and easy" />,
    text: <FormattedMessage defaultMessage="Purchase a gift subscription easily. It arrives in moments and we notify you of delivery." />
  },
  {
    heading: <FormattedMessage defaultMessage="Customizable" />,
    text: <FormattedMessage defaultMessage="Choose a design you love and personalize a message." />
  },
  {
    heading: <FormattedMessage defaultMessage="Send via email or print" />,
    text: <FormattedMessage defaultMessage="Choose the delivery date the gift subscription will be emailed. Or, print and hand-deliver." />
  }
];

const br = () => <br />;

const GiftValuePanel = () => {
  const intl = useIntl();

  return (
    <div className={styles.container}>
      <section>
        <div className={styles.give}>
          <Typography component="div" variant="heading600">
            <FormattedMessage defaultMessage="An easy way to give joy" />
          </Typography>
          <Typography component="div" variant="text200">
            <FormattedMessage defaultMessage="Send the gift of exploration to your friends and family with an AllTrails+ gift subscription." />
          </Typography>
        </div>
        <div className={styles.imageAndBlurbs}>
          <img alt={intl.formatMessage({ defaultMessage: 'AllTrails gift subscription' })} className={styles.img} loading="lazy" src={giftCard1Src} />
          <div className={styles.blurbs}>
            {blurbs.map((blurb, index) => (
              <div className={styles.blurb} key={index}>
                <Typography component="h4" variant="heading400">
                  {blurb.heading}
                </Typography>
                <Typography component="div" variant="text200">
                  {blurb.text}
                </Typography>
              </div>
            ))}
          </div>
        </div>
      </section>
      <section className={styles.perfectForAllContainer}>
        <div className={styles.perfect}>
          <Typography component="h2" variant="heading600">
            <FormattedMessage defaultMessage="Perfect for all occasions and<br></br> outdoor enthusiasts." values={{ br }} />
          </Typography>
          <Typography component="div" variant="text200">
            <FormattedMessage defaultMessage="Celebrate the people you appreciate on birthdays, holidays, graduations and more. With the ability to browse by activity, it’s a fitting gift for any type of outdoor explorer in your life." />
          </Typography>
        </div>
        <picture>
          <source srcSet={perfectAvifSrc} type="image/avif" />
          <source srcSet={perfectWebpSrc} type="image/webp" />
          <img
            alt={intl.formatMessage({
              defaultMessage:
                'Various individuals and groups enjoy different activities in nature: a backpacker steps forward with a smile, a family walks leisurely through a pine forest, and a cyclist begins their ascent up a lush mountain road.'
            })}
            className={styles.img}
            loading="lazy"
            src={perfectJpgSrc}
          />
        </picture>
      </section>
    </div>
  );
};

export default GiftValuePanel;
