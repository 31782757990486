import PaymentMethod from '@alltrails/analytics/enums/PaymentMethod';
import WebProPurchaseLocation from '@alltrails/analytics/enums/WebProPurchaseLocation';
import logPurchaseSuccess from '@alltrails/analytics/events/logPurchaseSuccess';
import LanguageRegionCode from '@alltrails/shared/types/LanguageRegionCode';
import Coupon from 'types/Coupon';
import CurrencyCode from 'types/CurrencyCode';
import Plan from 'types/Plan';
import { trackTinuitiPurchase } from '@alltrails/shared/utils/tinuitiUtils';
import { getAmplitudeInstance } from '@alltrails/analytics/amplitudeHelpers';
import { wrapUrlSafe } from 'utils/language_support_util';
import { trackTinuitiOOHPurchase } from 'utils/TinuitiOOHPixel';
import { getPurchaseScreenTypePlan } from './amplitudeHelper';
import { getPriceTotal } from './PlanUtils';
import logError from './logError';

function handleAttribution(
  userId: number,
  currencyCode: CurrencyCode,
  coupon: Coupon,
  plan: Plan,
  couponCode: string,
  webProPurchaseLocation: WebProPurchaseLocation,
  paymentMethod: PaymentMethod
) {
  // Only use passed-in userId if there isn't one stored on the current session in Amplitude.
  // Users can purchase on an associated userId without being authenticated through the use of the uid query
  // parameter used on promo emails, and therefore we want to associate the purchase with the correct user id
  // but without opening up our analytics to other weirdness outside of a purchase.
  const ampInstance = getAmplitudeInstance();
  const existingUserId = 'getUserId' in ampInstance ? ampInstance.getUserId() : undefined; // TODO: Need to declare `getUserId` in amplitudeHelpers

  const { recurlyPlanCode, priceTotal } = plan;
  const value = getPriceTotal(plan, coupon);

  // gtag events (not defined in local development)
  if (typeof gtag !== 'undefined') {
    if (!existingUserId) {
      // Set GA user id
      // https://developers.google.com/analytics/devguides/collection/gtagjs/cookies-user-id
      gtag('config', window.ga4_measure_id, {
        user_id: userId
      });
    }
    // Currently, the only purchase option for GA UA config
    gtag('event', 'page_view', { page_path: '/pro/year/success' });
    // GA4
    // https://developers.google.com/analytics/devguides/collection/ga4/reference/events#purchase
    gtag('event', 'purchase', {
      currency: currencyCode,
      value,
      items: [
        {
          item_id: recurlyPlanCode,
          coupon: couponCode,
          discount: Number(plan.priceTotal) - value,
          item_category: 'Pro Subscription',
          currency: currencyCode,
          price: priceTotal
        }
      ]
    });
  }

  // FB Pixel
  // https://developers.facebook.com/docs/meta-pixel/reference#:~:text=PAGE_VIEW-,Purchase,-When%20a%20purchase
  // Check for presence of fbq in case of content blockers
  if (typeof fbq !== 'undefined') {
    if (!existingUserId) {
      fbq('init', window.fb_pixel_id, {
        external_id: userId
      });
    }
    fbq('track', 'Purchase', {
      currency: currencyCode,
      value,
      content_type: 'product',
      content_ids: [recurlyPlanCode]
    });
  }

  // TikTok Pixel
  // https://ads.tiktok.com/help/article/get-started-pixel?lang=en
  if (typeof ttq !== 'undefined') {
    if (userId) {
      ttq.identify({
        external_id: userId
      });
    }
    ttq.track('CompletePayment', {
      content_type: 'product',
      description: 'Pro Subscription',
      quantity: 1,
      content_id: recurlyPlanCode,
      currency: currencyCode,
      value
    });
  }

  // Tinuiti Pixel
  if (existingUserId || userId) {
    const tinuitiUserId = existingUserId || userId;
    trackTinuitiPurchase(value.toString(), tinuitiUserId.toString());
  }

  // Tinuiti OOH Pixel
  trackTinuitiOOHPurchase(value, currencyCode);

  // Amplitude
  if (!existingUserId) {
    ampInstance.setUserId(userId.toString());
  }
  logPurchaseSuccess({
    campaign_id: couponCode,
    payment_method: paymentMethod,
    pro_upsell_experiment_variant_name: '', // only required on mobile
    product_id: recurlyPlanCode,
    purchase_screen_type_plan: getPurchaseScreenTypePlan(plan, coupon),
    purchase_screen_type: undefined, // only required on mobile
    transaction_date: '', // only required on mobile
    transaction_id: '', // only required on mobile
    web_purchase_location: webProPurchaseLocation
  });

  // MNTN Conversion Pixel
  try {
    if (window && window.localStorage) {
      window.localStorage.setItem('mntn-conversion-data', JSON.stringify({ orderId: '', totalOrderAmount: value }));
    }
  } catch (e) {
    logError(e);
  }
}

function handleRedirect(languageRegionCode: LanguageRegionCode, paymentMethod: PaymentMethod, returnTo?: string | (() => string)) {
  const params = new URLSearchParams(window.location.search);

  function redirectUser() {
    if (returnTo) {
      window.location.assign(typeof returnTo === 'function' ? returnTo() : returnTo);
    } else if (params.has('returnTo')) {
      window.location.assign(params.get('returnTo') as string); // this is already wrapped
    } else {
      window.location.assign(wrapUrlSafe('/plus/welcome', languageRegionCode));
    }
  }

  if (paymentMethod !== PaymentMethod.CreditCard) {
    // Add a short delay before redirecting to ensure that the user sees the loading overlay
    setTimeout(() => {
      redirectUser();
    }, 300);
  } else {
    redirectUser();
  }
}

// eslint-disable-next-line import/prefer-default-export
export function handlePaymentFormSuccess(
  userId: number,
  currencyCode: CurrencyCode,
  coupon: Coupon,
  plan: Plan,
  languageRegionCode: LanguageRegionCode,
  couponCode: string,
  webProPurchaseLocation: WebProPurchaseLocation,
  paymentMethod: PaymentMethod,
  returnTo?: string | (() => string)
) {
  // ensure that attribution does not cause any errors
  // that would prevent a user that successfully purchased plus
  // from continuing with the expected flow
  try {
    handleAttribution(userId, currencyCode, coupon, plan, couponCode, webProPurchaseLocation, paymentMethod);
  } catch (e) {
    logError(e);
  }

  handleRedirect(languageRegionCode, paymentMethod, returnTo);
}
