import { useState, useEffect } from 'react';
import { func, bool } from 'prop-types';
import { useIntl } from '@alltrails/shared/react-intl';
import ModalBackground from '@alltrails/shared/components/modals/ModalBackground';
import useMultiStepModal from '@alltrails/shared/hooks/useMultiStepModal';
import { ManagedMembersModal } from '@alltrails/modules/ManagedMembers';
import '@alltrails/modules/ManagedMembers/index.css';
import { buildStore } from '@alltrails/modules/UserConnections/redux';
import { Provider } from 'react-redux';
import IndividualPrivacyModal from '../IndividualPrivacyModal';
import useScreenLock from '../../../../hooks/useScreenLock';
import InitialPrivacyPolicyModal from '../InitialPrivacyPolicyModal';
import PrivacyPolicyPropType from '../../../../proptypes/PrivacyPolicyPropType';
import { policyToRadioForm, PRIVACY_POLICY_KEYS_MAP } from '../../../../utils/privacy_policy_helpers';
import { contextPropTypes } from '../../../../proptypes/shared';
import CustomProvider from 'components/CustomProvider';
import useLanguageRegionCode from '@alltrails/shared/hooks/useLanguageRegionCode';
import useExperiment from 'hooks/useExperiment';

const GeneralPrivacyPolicyModal = ({ context, initialPrivacyPolicy, closeModal, handleSave, loading }) => {
  const languageRegionCode = useLanguageRegionCode();
  const [privacyPolicy, updatePrivacyPolicy] = useState(initialPrivacyPolicy);
  const [individualPrivacyModalProps, setIndividualPrivacyModalProps] = useState({});
  const [activeSubject, setActiveSubject] = useState('');
  const [applyBulk, toggleApplyBulk] = useState(false);
  const [managedMembersListKey, setManagedMembersListKey] = useState('');
  const { mountedModal, activeModal, goBack, navigateTo, handleCloseModal, handleOnTransitionEnd } = useMultiStepModal('baseModal', closeModal);
  const experiment = useExperiment('web-community-collaborative-lists');
  const collaborativeListsFeatureFlag = experiment?.value === 'on';
  useScreenLock();
  const intl = useIntl();

  useEffect(() => {
    updatePrivacyPolicy(initialPrivacyPolicy);
  }, [initialPrivacyPolicy]);

  const onPrivacyClick = (subject, visibility) => {
    setActiveSubject(subject);
    setIndividualPrivacyModalProps(policyToRadioForm(subject, visibility, intl, collaborativeListsFeatureFlag));
    navigateTo('individualPrivacyModal');
  };

  const onManagedMembersListClick = listKey => {
    setManagedMembersListKey(listKey);
    navigateTo('managedMembersModal');
  };

  const handleAcceptCallback = value => {
    const [outerKey, resultKey] = PRIVACY_POLICY_KEYS_MAP[activeSubject];
    // for "yes or no" radio buttons the nesting is one level up
    updatePrivacyPolicy(prevState => {
      const payload =
        resultKey === activeSubject
          ? { ...prevState[outerKey], [activeSubject]: value }
          : { ...prevState[outerKey], [activeSubject]: { ...prevState[outerKey][activeSubject], [resultKey]: value } };

      return {
        ...prevState,
        [outerKey]: payload
      };
    });
  };

  const baseModal = privacyPolicy && (
    <InitialPrivacyPolicyModal
      goBack={goBack}
      privacyPolicy={privacyPolicy}
      isMounted={activeModal === mountedModal}
      handleOnTransitionEnd={handleOnTransitionEnd}
      onPrivacyClick={onPrivacyClick}
      onManagedMembersListClick={onManagedMembersListClick}
      handleCloseModal={handleCloseModal}
      loading={loading}
      handleSave={handleSave}
      applyBulk={applyBulk}
      toggleApplyBulk={toggleApplyBulk}
      intl={intl}
    />
  );

  const individualPrivacyModal = (
    <IndividualPrivacyModal
      goBack={goBack}
      isMounted={activeModal === mountedModal}
      onTransitionEnd={handleOnTransitionEnd}
      acceptCallback={handleAcceptCallback}
      {...individualPrivacyModalProps}
      subject={activeSubject}
    />
  );

  const managedMembersModal = (
    <Provider store={buildStore({ context })}>
      <ManagedMembersModal
        languageRegionCode={languageRegionCode}
        listKey={managedMembersListKey}
        isMounted={activeModal === mountedModal}
        goBack={goBack}
        closeModal={goBack}
        onTransitionEnd={handleOnTransitionEnd}
      />
    </Provider>
  );

  const activeModalOptions = {
    baseModal,
    individualPrivacyModal,
    managedMembersModal
  };

  return (
    <CustomProvider>
      <ModalBackground closeModal={handleCloseModal} goBack={goBack}>
        {activeModalOptions[activeModal]}
      </ModalBackground>
    </CustomProvider>
  );
};

GeneralPrivacyPolicyModal.propTypes = {
  context: contextPropTypes.isRequired,
  initialPrivacyPolicy: PrivacyPolicyPropType,
  closeModal: func.isRequired,
  handleSave: func.isRequired,
  loading: bool
};

GeneralPrivacyPolicyModal.defaultProps = {
  loading: false,
  initialPrivacyPolicy: null
};

export default GeneralPrivacyPolicyModal;
