import Button from '@alltrails/shared/denali/components/Button';
import { FormattedMessage } from '@alltrails/shared/react-intl';;
import * as styles from './GiveAllTrailsBanner.module.scss';

type Props = {
  handleClick?: () => void;
};

// https://www.figma.com/file/gZaqAej8HzoFkyrjlQ4cNs/Integrating-Brand-Changes?node-id=586%3A30927
const GiveAllTrailsBanner = ({ handleClick }: Props) => (
  <section className={styles.container}>
    <h3 className={styles.header}>
      <FormattedMessage defaultMessage="Give an AllTrails+ <br></br> gift subscription" values={{ br: () => <br /> }} />
    </h3>
    <div className={styles.subText}>
      <FormattedMessage defaultMessage="Help them get the adventure started." />
    </div>
    <div>
      <Button text={<FormattedMessage defaultMessage="Buy gift" />} onClick={handleClick} testId="gift-at-banner-buy-gift" variant="primary" />
    </div>
  </section>
);

export default GiveAllTrailsBanner;
