import { Activity, ActivityType } from '@alltrails/shared/types/activity';
import { Map } from '@alltrails/shared/types/map';
import Track, { TrackId } from '@alltrails/shared/types/track';
import { Trail as NewTrail } from '@alltrails/shared/types/trail';
import { Trail, sharedTrailFromTrail } from 'types/Trails';
import { getLocationName, getLocationSlug } from './location_parsing';

const getTrackUserName = (track: Track) => {
  const firstName = track.user?.firstName || track.user?.first_name;
  const lastName = track.user?.lastName || track.user?.last_name;
  return [firstName, lastName].filter(str => str && str.length > 0).join(' ');
};

export const getMapFromTrack = (track: Track, minutes: number): Map => ({
  id: track.ID as TrackId,
  slug: track.slug,
  name: track.name,
  minutes,
  meters: track.length || track.distance,
  user: { id: track.user?.id, name: getTrackUserName(track), slug: track.user?.slug },
  hasPhotos: track.photo_count > 0,
  locationName: getLocationName(track),
  locationSlug: getLocationSlug(track)
});

export const getActivityFromTrack = (track: Track, minutes: number): Activity => ({
  id: track.ID as TrackId,
  slug: track.slug,
  name: track.name,
  minutes,
  meters: track.length || track.distance,
  elevationGain: track.elevation_gain,
  activityType: (track.activities?.length ? track.activities[0] : '') as ActivityType,
  user: { id: track.user?.id, name: getTrackUserName(track), slug: track.user?.slug },
  createdAt: typeof track.created_at === 'string' ? track.created_at : track.created_at.toUTCString(),
  hasPhotos: track.photo_count > 0
});

// Remove the "trail/"" from the trail slug so that we can treat the slug like all others (no path info)
const formatTrailSlug = (slug: string) => (slug.startsWith('trail/') ? slug.slice(6) : slug);

export const getTrailForTrailCard = (trail: Trail, minutes: number): NewTrail => {
  const trailCopy = { ...trail };
  trailCopy.slug = formatTrailSlug(trailCopy.slug);
  trailCopy.duration_minutes = minutes;

  const newTrail = sharedTrailFromTrail(trailCopy);
  newTrail.area = { name: getLocationName(trail), slug: getLocationSlug(trail) };
  return newTrail;
};
