import Button from '@alltrails/shared/denali/components/Button';
import CustomProvider from 'components/CustomProvider';
import { FormEvent, ReactNode, Suspense, useRef, useState } from 'react';
import { FormattedMessage } from '@alltrails/shared/react-intl';
import { LanguageSupportUtil } from 'utils/language_support_util';
import { CardElementState, Elements, RecurlyProvider, useRecurly } from '@recurly/react-recurly';
import type { RecurlyError, TokenPayload } from '@recurly/recurly-js';
import useLanguageRegionCode from '@alltrails/shared/hooks/useLanguageRegionCode';
import { ApiError, put } from '@alltrails/shared/api';
import { NoSsr, Typography } from '@material-ui/core';
import * as styles from './styles/UpdatePaymentForm.module.scss';
import TokenizedCreditCardForm from '../TokenizedCreditCardForm';
import useTokenizedFormValidation from '../TokenizedCreditCardForm/hooks/useTokenizedFormValidation';
import { CARD_VALIDATION_NAMES } from '../TokenizedCreditCardForm/types';

type FormData = {
  billing_first_name?: string;
  billing_last_name?: string;
  cc_expiration_month?: number;
  cc_expiration_year?: number;
};

type Props = {
  updateBillingUrl?: string;
  formData?: FormData;
};

type TokenizeFormProps = {
  updateBillingUrl?: string;
  formData?: FormData;
};

const getInitialFullName = (formData: FormData) => {
  if (formData?.billing_first_name && formData?.billing_last_name) {
    return `${formData.billing_first_name} ${formData.billing_last_name}`;
  }
  return null;
};

export const TokenizedUpdatePaymentForm = ({ updateBillingUrl, formData }: TokenizeFormProps) => {
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [serverError, setServerError] = useState<ReactNode>(null);
  const reactRecurlyInstance = useRecurly();
  const formRef = useRef();
  const languageRegionCode = useLanguageRegionCode();
  const initialName = getInitialFullName(formData);
  const {
    validationErrors,
    setClientValidationErrors,
    setTokenRequestErrors,
    hasValidationErrors: hasTokenizedValidationErrors
  } = useTokenizedFormValidation(!!initialName);

  const defaultServerError = <FormattedMessage defaultMessage="Unable to update your credit card info." />;

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();

    if (submitting || hasTokenizedValidationErrors()) {
      return;
    }

    setSubmitting(true);

    const onRecurlyToken = async (err: RecurlyError, token: TokenPayload) => {
      if (err) {
        setTokenRequestErrors(err);
        setSubmitting(false);
      } else {
        try {
          const { redirectUrl } = await put<{ redirectUrl: string }>(updateBillingUrl, { cc_token: token.id });
          window.location.assign(LanguageSupportUtil.wrapUrlSafe(redirectUrl, languageRegionCode));
        } catch (e) {
          if (e instanceof ApiError && e?.data?.errors?.base?.[0]) {
            setServerError(e.data.errors.base[0]);
          } else {
            setServerError(defaultServerError);
          }
          setSubmitting(false);
        }
      }
    };

    try {
      reactRecurlyInstance.token(formRef.current, onRecurlyToken);
    } catch (e) {
      setServerError(defaultServerError);
      setSubmitting(false);
    }
  };

  return (
    <form className={styles.form} id="update-payment-form" onSubmit={handleSubmit} ref={formRef}>
      <TokenizedCreditCardForm
        initialFullName={initialName}
        validationErrors={validationErrors}
        handleInputChange={(
          input: CARD_VALIDATION_NAMES,
          value: string | { number: CardElementState; expiry: CardElementState; cvv: CardElementState }
        ) => {
          if (serverError) {
            setServerError(null);
          }
          setClientValidationErrors(input, value);
        }}
        isCouponInputShown={false}
      />
      {serverError && <Typography color="error">{serverError}</Typography>}
      <Button
        testId="update-payment-submit"
        text={<FormattedMessage defaultMessage="Save" />}
        type="submit"
        variant="primary"
        width="full"
        loading={submitting}
      />
      <Button
        linkInfo={{ href: '/my/profile/edit' }}
        onClick={undefined}
        testId=""
        text={<FormattedMessage defaultMessage="Cancel" />}
        variant="flat"
      />
    </form>
  );
};

const UpdatePaymentPage = ({ updateBillingUrl, formData }: Props) => (
  <div className={styles.billingContainer}>
    <div className={styles.title}>
      <FormattedMessage defaultMessage="Credit card" />
    </div>
    <div>
      <NoSsr>
        <Suspense fallback={null}>
          <RecurlyProvider publicKey={__AT_DATA__.recurlyPublicKey}>
            <Elements>
              <TokenizedUpdatePaymentForm updateBillingUrl={updateBillingUrl} formData={formData} />
            </Elements>
          </RecurlyProvider>
        </Suspense>
      </NoSsr>
    </div>
  </div>
);

const UpdatePaymentPageWrapper = ({ updateBillingUrl, formData }: Props) => (
  <CustomProvider>
    <UpdatePaymentPage updateBillingUrl={updateBillingUrl} formData={formData} />
  </CustomProvider>
);

export default UpdatePaymentPageWrapper;
