import { Area } from '@alltrails/shared/types/area';
import type { GarminCourse } from '@alltrails/shared/types/trail';
import type { Trail } from '@alltrails/shared/types/Trails/Trail';
import { Trail as SharedTrail, TrailId, TrailPhoto } from '@alltrails/shared/types/trail';

type FaqWeatherForecast = {
  dayOfTheWeek: string;
  dateTime: string;
  maxTemperature: number;
  minTemperature: number;
  weatherText: string;
};

const sharedTrailFromTrail = (trail: Trail): SharedTrail => ({
  id: trail.ID,
  name: trail.name,
  slug: trail.slug,
  area: { name: trail.area_name, slug: trail.area_slug } as Area,
  avgRating: trail.avg_rating,
  defaultActivityStats: { difficulty: Number(trail.difficulty_rating), rating: trail.avg_rating },
  defaultPhoto: trail.has_profile_photo || trail.profile_photo_data ? ({} as TrailPhoto) : undefined,
  trailCounts: { reviewCount: trail.num_reviews },
  trailDetail: { description: trail.description },
  trailGeoStats: { durationMinutes: trail.duration_minutes?.toString() || '0', length: trail.length || 0 }
});

export default Trail;
export { TrailId, Trail, sharedTrailFromTrail, GarminCourse, FaqWeatherForecast };
