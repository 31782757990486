import Plan from 'types/Plan';
import CurrencyCode from 'types/CurrencyCode';
import GiftPlan from 'components/GiftFlow/GiftPlan';
import { FormattedMessage } from '@alltrails/shared/react-intl';
import CheckFilled from '@alltrails/shared/icons/CheckFilled';
import { COLOR_PLUS_300, COLOR_TEXT_PRIMARY_INVERSE } from '@alltrails/shared/denali/tokens';
import Typography from '@alltrails/shared/denali/components/Typography';
import { MEDIA_QUERY_SMALL_DOWN } from 'utils/constants/breakpoints';
import Chevron from '@alltrails/shared/icons/Chevron';
import useToggle from '@alltrails/shared/hooks/useToggle';
import { Key } from 'react';
import classNames from 'classnames';
import * as styles from './GiftingHeader.module.scss';
import heroAvifSrc from './hero.avif';
import heroWebpSrc from './hero.webp';
import heroJpgSrc from './hero.jpg';
import hero768wAvifSrc from './hero-768w.avif';
import hero768wWebpSrc from './hero-768w.webp';
import hero768wJpgSrc from './hero-768w.jpg';

type GiftingHeaderProps = {
  plan: Plan;
  currencyCode: CurrencyCode;
};

const features = [
  <FormattedMessage defaultMessage="Download maps to stay on track without service" />,
  <FormattedMessage defaultMessage="Never miss a turn with wrong-turn alerts" />,
  <FormattedMessage defaultMessage="Keep your crew in the loop with Live Share" />,
  <FormattedMessage defaultMessage="Know what to expect with real-time map overlays" />
];

// Desktop: https://www.figma.com/file/gZaqAej8HzoFkyrjlQ4cNs/Integrating-Brand-Changes?node-id=586%3A30927
// Mobile: https://www.figma.com/file/gZaqAej8HzoFkyrjlQ4cNs/Integrating-Brand-Changes?node-id=5958%3A97462
const GiftingHeader = ({ plan, currencyCode }: GiftingHeaderProps) => {
  const [isOpen, toggle] = useToggle();

  return (
    <section className={styles.section}>
      <div className={styles.content}>
        <div className={styles.featuresAndPlan}>
          <div className={styles.features}>
            <Typography component="h1" color="inverse" data-testid="give_the_gift" variant="heading700">
              <FormattedMessage defaultMessage="Give the gift of adventure" />
            </Typography>
            <div>
              <div className={styles.toggle}>
                <Typography color="inverse" variant="heading200">
                  <FormattedMessage defaultMessage="Features designed for all" />
                </Typography>
                <button
                  className={styles.button}
                  onClick={() => {
                    toggle();
                  }}
                  type="button"
                >
                  <Chevron size="sm" orientation={isOpen ? 'up' : 'down'} color={COLOR_TEXT_PRIMARY_INVERSE} />
                </button>
              </div>
              <ul className={classNames(styles.ul, isOpen && styles.openul)}>
                {features.map((feature, index) => (
                  <li key={index as Key}>
                    <CheckFilled color={COLOR_PLUS_300} />
                    <Typography color="inverse" variant="text200">
                      {feature}
                    </Typography>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className={classNames(styles.plan, isOpen && styles.open)}>
            <GiftPlan currencyCode={currencyCode} plan={plan} />
          </div>
        </div>
        <picture className={styles.picture}>
          <source media={MEDIA_QUERY_SMALL_DOWN} srcSet={hero768wAvifSrc} type="image/avif" />
          <source media={MEDIA_QUERY_SMALL_DOWN} srcSet={hero768wWebpSrc} type="image/webp" />
          <source media={MEDIA_QUERY_SMALL_DOWN} srcSet={hero768wJpgSrc} type="image/jpg" />
          <source srcSet={heroAvifSrc} type="image/avif" />
          <source srcSet={heroWebpSrc} type="image/webp" />
          <img className={styles.img} src={heroJpgSrc} alt="" />
        </picture>
      </div>
    </section>
  );
};

export default GiftingHeader;
