// extracted by mini-css-extract-plugin
export var button = "GiftingHeader-module__button___F7lf_";
export var content = "GiftingHeader-module__content___vbNNN";
export var features = "GiftingHeader-module__features____AmPc";
export var featuresAndPlan = "GiftingHeader-module__featuresAndPlan___EePhV";
export var img = "GiftingHeader-module__img___EU4QJ";
export var open = "GiftingHeader-module__open___Ecuob";
export var openul = "GiftingHeader-module__openul___Dacv1";
export var picture = "GiftingHeader-module__picture___Se6Md";
export var plan = "GiftingHeader-module__plan___vHeNQ";
export var section = "GiftingHeader-module__section___XJQeH";
export var toggle = "GiftingHeader-module__toggle___brjAO";
export var ul = "GiftingHeader-module__ul___IWySZ";