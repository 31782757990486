import CustomProvider from 'components/CustomProvider';
import CurrencyCode from 'types/CurrencyCode';
import RedemptionHeader from './RedemptionHeader';
import RedemptionForm from './RedemptionForm';
import FrequentlyAskedQuestions from '../GiftFlow/Landing/FrequentlyAskedQuestions';
import { container } from './styles/styles.module.scss';

type RedemptionPageProps = {
  context: {
    couponCode: string;
    currencyCode: CurrencyCode;
  };
};

const RedemptionPage = ({ context }: RedemptionPageProps) => (
  <CustomProvider>
    <div className={container}>
      <RedemptionHeader />
      <RedemptionForm currencyCode={context.currencyCode} couponCode={context.couponCode} />
      <FrequentlyAskedQuestions />
    </div>
  </CustomProvider>
);

export default RedemptionPage;
