import { useMemo } from 'react';
import classNames from 'classnames';
import { TrailCardV2 } from 'components/cards/TrailCard';
import { Trail } from 'types/Trails';
import ListMethods from 'types/ListMethods';
import Typography from '@alltrails/shared/denali/components/Typography';
import { RiverAnalyticsData } from '@alltrails/modules/Lists/listAmplitudeHelpers';
import CardLocation from '@alltrails/analytics/enums/CardLocation';
import * as styles from './styles/styles.module.scss';
import River from '../../shared/River';
import ShowMoreCard from './ShowMoreCard';
import TrailRiverShimmer from '../../shimmers/TrailRiverShimmer';
import { HOME_PAGE_WIDTH_BREAKPOINT } from '../../../utils/constants/breakpoints';

const slickSettings = { slidesToShow: 4, lazyLoad: 'progressive', slidesToScroll: 4, speed: 750 };
const ARROW_STYLES = { next: styles.next, previous: styles.previous };

type HitTrail = Trail & { exploreMoreUrl: string };

type Props = {
  hits: HitTrail[];
  isFirstRiverOnPage?: boolean;
  title: string;
  windowWidth: number;
  className?: string;
  listMethods: ListMethods;
  riverAnalyticsData?: RiverAnalyticsData;
};

const TrailRiver = ({ hits, isFirstRiverOnPage, title, windowWidth, className, listMethods, riverAnalyticsData }: Props): JSX.Element => {
  const isWideScreen = useMemo(() => windowWidth && windowWidth >= HOME_PAGE_WIDTH_BREAKPOINT, [windowWidth]);

  const { isComplete } = listMethods;

  const cardIndexForBadgeAnimation = useMemo((): number | null => (hits || []).findIndex(hit => isComplete('trail', hit.ID)), [hits, isComplete]);

  if (!hits) {
    return <TrailRiverShimmer className={classNames(styles.riverShimmer, className)} />;
  }

  return (
    <div className={classNames(styles.container, className)}>
      <div className={styles.riverContainer}>
        <Typography variant="heading400" component="h2" mb="12" className={styles.title}>
          {title}
        </Typography>
        <River useSlick={isWideScreen} slickSettings={slickSettings} className={styles.riverClass} arrowStyles={ARROW_STYLES}>
          {hits.map((hit, index) => (
            <div className={styles.trailCardContainer} data-testid={`${title}`} key={hit.ID || hit.exploreMoreUrl}>
              {hit.exploreMoreUrl ? (
                <ShowMoreCard url={hit.exploreMoreUrl} riverAnalyticsData={riverAnalyticsData} cardIndex={index} />
              ) : (
                <TrailCardV2
                  isCompletionBadgeAutoExpanded={isFirstRiverOnPage && cardIndexForBadgeAnimation === index}
                  className={styles.trailCard}
                  allowItemMoving={false}
                  handleFavoriteClick={() =>
                    listMethods.handleFavoriteClick({
                      type: hit.type,
                      id: hit.ID,
                      objectId: hit.objectID,
                      riverAnalyticsData,
                      index,
                      detailedCardLocation: CardLocation.WebHomepageRiver
                    })
                  }
                  isCompleted={listMethods.isComplete(hit.type, hit.ID)}
                  isFavorite={listMethods.isFavorite(hit.type, hit.ID)}
                  isVerified={listMethods.isVerified(hit.type, hit.ID)}
                  key={`trail-${hit.ID}`}
                  trail={hit}
                  maxPhotos={1}
                  riverAnalyticsData={riverAnalyticsData}
                  cardIndex={index}
                  isPhotoZoomEnabled
                />
              )}
            </div>
          ))}
        </River>
      </div>
    </div>
  );
};

export default TrailRiver;
