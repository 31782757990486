import PurchaseScreenTypePlan from '@alltrails/analytics/enums/PurchaseScreenTypePlan';
import SearchItemType from '@alltrails/analytics/enums/SearchItemType';
import SearchSortFilter from '@alltrails/analytics/enums/SearchSortFilter';
import type { User } from 'types/User';
import Coupon from 'types/Coupon';
import Plan from 'types/Plan';
import { AlgoliaResultType } from 'types/Search/Algolia';
import { Sort } from 'types/Search/Sort';
import Cookies from 'js-cookie';
import { isTrial } from './PlanUtils';

const typeMapping = {
  [AlgoliaResultType.Trail]: SearchItemType.Trail,
  [AlgoliaResultType.Track]: SearchItemType.Track,
  [AlgoliaResultType.Place]: SearchItemType.Place,
  [AlgoliaResultType.List]: SearchItemType.List,
  [AlgoliaResultType.Area]: SearchItemType.Area,
  [AlgoliaResultType.Map]: SearchItemType.Map,
  [AlgoliaResultType.Filter]: SearchItemType.Attribute,

  // TODO DISCO-604 map to the actual Amplitude analytics when ready.
  [AlgoliaResultType.POI]: AlgoliaResultType.POI as unknown as SearchItemType
};

const sortMapping = {
  [Sort.BEST_MATCH]: SearchSortFilter.BestMatch,
  [Sort.CLOSEST]: SearchSortFilter.Closest,
  [Sort.MOST_POPULAR]: SearchSortFilter.MostPopular,
  [Sort.NEWLY_ADDED]: SearchSortFilter.NewlyAdded,

  // These aren't tracked in Amplitude today. Just return the original sort value cast as an Amplitude value.
  [Sort.NAME]: Sort.NAME as unknown as SearchSortFilter,
  [Sort.LIST_ORDER]: Sort.LIST_ORDER as unknown as SearchSortFilter,
  [Sort.DATE]: Sort.DATE as unknown as SearchSortFilter,
  [Sort.RECENTLY_ADDED]: Sort.RECENTLY_ADDED as unknown as SearchSortFilter
};

function algoliaTypeToAmplitudeType(algoliaResultType: AlgoliaResultType): SearchItemType {
  return typeMapping[algoliaResultType];
}

function sortToAmplitudeSort(sort: Sort): SearchSortFilter {
  return sortMapping[sort];
}

function getPurchaseScreenTypePlan(plan: Plan, coupon?: Coupon): PurchaseScreenTypePlan {
  if (coupon) {
    return PurchaseScreenTypePlan.Promo;
  }

  if (isTrial(plan)) {
    return PurchaseScreenTypePlan.FreeTrial;
  }

  return PurchaseScreenTypePlan.NonTrial;
}

async function variants(user?: User) {
  const params = new URLSearchParams();
  params.set('device_id', __AT_DATA__.amplitudeDeviceId);

  if (user) {
    params.set('user_id', String(user.id));
  }

  const context = { user_properties: { 'osano cookie': Cookies.get('osano_consentmanager') !== undefined, 'user type': user?.pro ? 'pro' : 'free' } };

  params.set('context', JSON.stringify(context));

  try {
    const response = await fetch(`https://api.lab.amplitude.com/v1/vardata?${params.toString()}`, {
      headers: {
        Authorization: `Api-Key ${__AT_DATA__.amplitudeDeploymentKey}`
      }
    });

    const variants = await response.json();

    Object.keys(variants).forEach(key => {
      // Ruby uses "value" but the raw response uses "key"
      variants[key].value = variants[key].key;
      delete variants[key].key;
    });

    return variants;
  } catch (e) {
    // ignore exceptions usually caused by ad blockers such as Ghostery or uBlock
    return {};
  }
}

export { algoliaTypeToAmplitudeType, getPurchaseScreenTypePlan, sortToAmplitudeSort, variants };
