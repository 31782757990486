import { FormattedMessage } from '@alltrails/shared/react-intl';
import Button from '@alltrails/shared/denali/components/Button';
import CurrencyCode from 'types/CurrencyCode';
import Plan from 'types/Plan';
import * as formStyles from 'components/forms/styles/styles.module.scss';
import Coupon from 'types/Coupon';
import PlanTypeCard from 'components/forms/pro/PlanTypeCard';
import * as baseStyles from '../../../styles/baseStyles.module.scss';
import * as styles from './GiftPlan.module.scss';
import { wrapUrlSafe } from 'utils/language_support_util';
import useLanguageRegionCode from '@alltrails/shared/hooks/useLanguageRegionCode';

type Props = {
  currencyCode: CurrencyCode;
  isCouponApplied?: boolean;
  plan: Plan;
  verifiedCoupon?: Coupon;
};

function GiftPlan({ plan, currencyCode, verifiedCoupon, isCouponApplied }: Props) {
  const languageRegionCode = useLanguageRegionCode();

  return (
    <div>
      <div className={(baseStyles.container, formStyles.container)}>
        <div className={baseStyles.section}>
          <div className={(baseStyles.row, formStyles.group)}>
            <div className={baseStyles.row}>
              <PlanTypeCard
                billedAtOnce
                plan={plan}
                isActive
                currencyCode={currencyCode}
                coupon={verifiedCoupon}
                isCouponApplied={isCouponApplied}
                extraClasses={styles.card}
              />
            </div>
          </div>
          <div className={baseStyles.row} data-testid="Get Started">
            <Button
              linkInfo={{ href: wrapUrlSafe('/gift/customize', languageRegionCode), useReactRouter: true }}
              text={<FormattedMessage defaultMessage="Buy gift" />}
              testId="Buy gift"
              variant="primary"
            />
          </div>
        </div>
        <div className={styles.cancel}>
          <FormattedMessage defaultMessage="Cancel within 30 days and receive a full-refund." />
        </div>
      </div>
    </div>
  );
}

export default GiftPlan;
