import { TrackId } from '@alltrails/shared/types/track';
import type { AreaId } from '@alltrails/shared/types/area';
import { TrailId } from 'types/Trails';
import { FilterConfig } from '.';

type AlgoliaResults = {
  hits: AlgoliaHit[];
  hitsPerPage: number;
  nbHits: number;
};

// An array of lat, lng, lat, lng, repeating to represent a polygon.
type AlgoliaPolygon = number[];

// One or more polygons can be used when searching Algolia using insidePolygon.
type InsidePolygon = AlgoliaPolygon[];

enum AlgoliaResultType {
  Area = 'area',
  Filter = 'filter',
  List = 'list',
  Map = 'map',
  Place = 'place',
  POI = 'poi',
  Trail = 'trail',
  Track = 'track'
}

type AlgoliaId = TrailId | TrackId | AreaId;

enum AlgoliaFilterKey {
  Access = 'access',
  Activity = 'activity',
  Activities = 'activities',
  Feature = 'feature',
  Features = 'features',
  Suitability = 'suitability'
}

// We often mutate our algolia hits and add/change fields. We should have a formal
// process for converting from an AlgoliaHit to a standard result object.
// TODO https://alltrails.atlassian.net/browse/TD-300
type AlgoliaHit = {
  _highlightResult?: unknown;
  ID?: AlgoliaId;
  adjustedPopularity?: number;
  area_name?: string;
  area_type?: string;
  city_name?: string;
  country_id?: number;
  country_name?: string;
  created_at?: number;
  distanceToCenter?: number;
  filters?: {
    [AlgoliaFilterKey.Access]?: string[];
    [AlgoliaFilterKey.Activity]?: string[];
    [AlgoliaFilterKey.Activities]?: string[];
    [AlgoliaFilterKey.Feature]?: string[];
    [AlgoliaFilterKey.Features]?: string[];
    [AlgoliaFilterKey.Suitability]?: string[];
  };
  highlightedName?: string;
  location?: string;
  name?: string;
  objectID?: string;
  order?: number;
  popularity?: number;
  slug?: string;
  state_name?: string;
  type?: AlgoliaResultType;
  url?: string;
  _geoloc?: {
    lat: number;
    lng: number;
  };
  subtype?: 'peak' | 'waterfall';
};

type AlgoliaSearchFunction = (changes: { [key: string]: FilterConfig }) => Promise<AlgoliaResults>;

export { AlgoliaFilterKey, AlgoliaHit, AlgoliaId, AlgoliaResults, AlgoliaResultType, AlgoliaSearchFunction, InsidePolygon };
