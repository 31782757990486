import isEqual from 'lodash.isequal';
import { FormattedMessage } from '@alltrails/shared/react-intl';
import { PageStrings } from '@alltrails/shared/utils/constants/pageStringHelpers';
import { getQueryStringValue } from './window_helpers';
import { LanguageSupportUtil } from './language_support_util';
import { lngLatBoundsToAlgoliaBounds, isMultiPolygon, normalizeMultiPolygonData } from './at_map_helpers';
import { filterKeyToConstructor, newFilterConfigForKey, newSortFilterConfig } from './filters/filterConfigs';

const ET_PHOTO_USER_ID = '13073460';
const ET_PHOTO_USER_SLUG = 'alltrails-user';

const TwoWeeksInSeconds = 60 * 60 * 24 * 14;

export const CARD_ATTRIBUTES = [
  'ID',
  '_cluster_geoloc',
  '_geoloc',
  'activities',
  'area_id',
  'area_name',
  'area_slug',
  'avg_rating',
  'city_id',
  'city_name',
  'country_id',
  'country_name',
  'created_at',
  'difficulty_rating',
  'duration_minutes',
  'duration_minutes_cycling',
  'duration_minutes_hiking',
  'duration_minutes_mountain_biking',
  'duration_minutes_trail_running',
  'elevation_gain',
  'filters',
  'has_profile_photo',
  'is_closed',
  'is_private_property',
  'length',
  'name',
  'num_photos',
  'num_reviews',
  'photo_count',
  'popularity',
  'profile_photo_data',
  'route_type',
  'slug',
  'state_id',
  'state_name',
  'type',
  'units',
  'user',
  'verified_map_id',
  'visitor_usage'
];

export const SEARCH_INPUT_ATTRIBUTES = [
  'ID',
  '_geoloc',
  'area_name',
  'area_slug',
  'country_name',
  'filters',
  'name',
  'slug',
  'state_name',
  'type',
  'subtype'
];

const SearchFiltersUtil = {
  getSearchFilters(context, page, initialUserSlug = null, intl) {
    // This logic is the same as newFilters in filter.ts;
    // We are trying to avoid circular deps
    const filters = {};

    Object.keys(filterKeyToConstructor).forEach(key => {
      filters[key] = newFilterConfigForKey(key, { context, page, initialUserSlug, intl });
    });

    return filters;
  },
  formatTimeDuration(valueMinutes) {
    if (valueMinutes === 'multi-day' || valueMinutes === '720+' || valueMinutes === 720) {
      return <FormattedMessage defaultMessage="Multi-day" />;
    }
    const mins = parseInt(valueMinutes);
    const hrs = Math.floor(mins / 60);
    let string = '';
    if (hrs >= 1) {
      const remainderMins = mins % 60;
      string = `${hrs} h ${remainderMins} m`;
    } else {
      string = `${mins} m`;
    }
    return string;
  },
  getFilterDisplayFunction(key) {
    switch (key) {
      case 'km':
        return value => `${value} km`;
      case 'mi':
        return value => `${value} mi`;
      case 'm':
        return value => `${value} m`;
      case 'ft':
        return value => `${value} ft`;
      case 'duration':
        return this.formatTimeDuration;
      default:
        return value => value;
    }
  },
  toggleFilter(filters, filterType, filterToToggle) {
    if (filters.hasOwnProperty(filterType) && filters[filterType] != null && filters[filterType].hasOwnProperty(filterToToggle)) {
      filters[filterType][filterToToggle].selected = !filters[filterType][filterToToggle].selected;
    }
    return filters;
  },
  sliderFilter(filters, filterType, sliderValues) {
    if (filters[filterType] && filters[filterType].value !== sliderValues.convertedRange) {
      filters[filterType].value = sliderValues.convertedRange;
      filters[filterType].displayValue = sliderValues.displayRange;
      if (sliderValues.formattedRange) {
        filters[filterType].formattedRange = sliderValues.formattedRange;
      }
    }
    return filters;
  },
  toggleRadioFilter(filters, filterType, filterToToggle) {
    if (filters.hasOwnProperty(filterType) && filters[filterType] != null && filters[filterType].hasOwnProperty(filterToToggle)) {
      filters[filterType] = Object.keys(filters[filterType]).reduce(
        (memo, nextKey) => ({
          ...memo,
          [nextKey]: {
            ...filters[filterType][nextKey],
            selected: nextKey === filterToToggle
          }
        }),
        {}
      );
    }
    return filters;
  },
  clearRadioFilter(filters, filterType, page, intl) {
    if (filterType === 'sort') {
      filters.sort = newSortFilterConfig({ page, intl });
      return filters;
    }

    if (filters.hasOwnProperty(filterType) && filters[filterType] != null) {
      filters[filterType] = Object.keys(filters[filterType]).reduce(
        (memo, nextKey) => ({
          ...memo,
          [nextKey]: {
            ...filters[filterType][nextKey],
            selected: false
          }
        }),
        {}
      );
    }
    return filters;
  },
  // deprecated - this set behavior is a bit like a toggle, but not quite. If the value is being set to the existing value...
  // e.g. filters.whatever = 3; setOrToggleFilter(filters, 'whatever', 3);
  // ...then the filter actually gets set to null. This seems like not quite a toggle and not quite a set.
  setOrToggleFilter(filters, filterToSet, value) {
    // Shortcut the queryTerm case, we always want to set it
    if (filters.hasOwnProperty(filterToSet) && (filters[filterToSet] != value || filterToSet == 'queryTerm')) {
      filters[filterToSet] = value;
    } else {
      filters[filterToSet] = null;
    }
    return filters;
  },
  setFilter(filters, filterToSet, value) {
    if (filters.hasOwnProperty(filterToSet) && (filters[filterToSet] != value || filterToSet == 'queryTerm')) {
      filters[filterToSet] = value;
    }
    return filters;
  },
  setZoomLevelFilter(filters, value) {
    filters.zoomLevel = value;
    return filters;
  },
  setBoundsFilter(filters, latTopLeft, lngTopLeft, latBottomRight, lngBottomRight) {
    const mod = (n, m) => ((n % m) + m) % m;
    const wrapBoundsLongitude = lng => {
      if (lng < 0 && lng > -180) return lng;
      if (lng > 0 && lng < 180) return lng;
      return mod(lng + 180, 360) - 180;
    };

    filters.boundingBox = {
      latitudeTopLeft: latTopLeft,
      longitudeTopLeft: wrapBoundsLongitude(lngTopLeft),
      latitudeBottomRight: latBottomRight,
      longitudeBottomRight: wrapBoundsLongitude(lngBottomRight)
    };
    return filters;
  },
  formatTrailIdArrayForAlgolia(ids, positive = true) {
    return `(${ids.map(id => `${positive ? '' : 'NOT '}objectID:trail-${id}`).join(positive ? ' OR ' : ' AND ')})`;
  },
  getAlgoliaCompletedFilterString(filters, page, completedIds, verifiedIds) {
    let completedFilterString = '';

    // find which keys are selected and unselected
    const selected = [];
    const unselected = [];
    Object.keys(filters.completed).forEach(itemKey => {
      const filterItem = filters.completed[itemKey];
      if (filterItem.selected) {
        selected.push(filterItem.value);
      } else {
        unselected.push(filterItem.value);
      }
    });

    // Form arrays of trail ids for completed, verified, and completed-unverified
    completedIds.push('NULL'); // allows correct filtering with empty list
    verifiedIds.push('NULL'); // allows correct filtering with empty list
    const unverifiedIds = completedIds.filter(id => verifiedIds.indexOf(id) < 0);
    unverifiedIds.push('NULL');

    // Create filter string based on which of 3 options is either selected or unselected
    // (filter is not applied when all options are selected or all options are unselected)
    if (selected.length == 1) {
      // unselected.length == 2
      switch (selected[0]) {
        case 'not_completed':
          completedFilterString = this.formatTrailIdArrayForAlgolia(unverifiedIds.concat(verifiedIds), false);
          break;
        case 'completed':
          completedFilterString = this.formatTrailIdArrayForAlgolia(unverifiedIds);
          break;
        case 'verified_completed':
          completedFilterString = this.formatTrailIdArrayForAlgolia(verifiedIds);
          break;
        default:
          break;
      }
    } else if (unselected.length == 1) {
      // selected.length == 2
      switch (unselected[0]) {
        case 'not_completed':
          completedFilterString = this.formatTrailIdArrayForAlgolia(unverifiedIds.concat(verifiedIds));
          break;
        case 'completed':
          completedFilterString = this.formatTrailIdArrayForAlgolia(unverifiedIds, false);
          break;
        case 'verified_completed':
          completedFilterString = this.formatTrailIdArrayForAlgolia(verifiedIds, false);
          break;
        default:
          break;
      }
    }

    return completedFilterString;
  },
  createAlgoliaMultFilterString(filter, algoliaKeyAndComparator, joinOperator) {
    const filterSubStrings = [];
    Object.keys(filter).forEach(itemKey => {
      // ignore keys that aren't selected
      if (!filter[itemKey].selected) {
        return;
      }
      // if key is selected, add to joined filter strings, format differently if value is an object
      if (typeof filter[itemKey].value === 'object') {
        filterSubStrings.push(`(${filter[itemKey].value.map(val => algoliaKeyAndComparator + val).join(' OR ')})`);
      } else {
        filterSubStrings.push(algoliaKeyAndComparator + filter[itemKey].value);
      }
    });
    return filterSubStrings.join(` ${joinOperator} `);
  },
  createTypeIDFilterString(filters, page) {
    // Create trail id filter
    let trailIds;
    if (filters.trailIds && filters.trailIds.length > 0) {
      trailIds = `(${filters.trailIds.map(id => `objectID:trail-${id}`).join(' OR ')})`;
    }

    // Change search to type:tracks if on tracks page
    if (page === PageStrings.EXPLORE_USERS_TRACKS_PAGE && filters.initialUserSlug == ET_PHOTO_USER_SLUG) {
      return `type:track AND user.id:${ET_PHOTO_USER_ID}`;
    }
    if (page === PageStrings.EXPLORE_COMMUNITY_CONTENT_PAGE) {
      // Show AtMaps that are not tied to trails, not hidden, and that are not missing a polyline
      return '(type:map OR type:track) AND trail_id=0 AND hidden=0 AND missing_polyline=0';
    }
    const listPages = [PageStrings.EXPLORE_COMPLETED_PAGE, PageStrings.EXPLORE_FAVORITE_PAGE, PageStrings.EXPLORE_CUSTOM_PAGE];
    if (listPages.includes(page)) {
      if (!trailIds) {
        return `objectID:trail-null`;
      }
      return `${[trailIds].filter(ids => !!ids && ids.length > 0).join(' OR ')}`;
    }
    if (trailIds) {
      return trailIds;
    }
    return 'type:trail';
  },
  createAlgoliaQueryObject({
    insidePolygon,
    filters,
    page,
    completedIds,
    verifiedIds,
    analyticsTags = null,
    attributesToRetrieve = null,
    attributesToHighlight = null,
    isParksAssociatedTrailsFeatureFlag = null
  }) {
    const algoliaQueryObject = { hitsPerPage: 1000 };
    if (analyticsTags) {
      algoliaQueryObject.analyticsTags = analyticsTags;
    }

    if (attributesToRetrieve) {
      algoliaQueryObject.attributesToRetrieve = attributesToRetrieve;
    }

    if (attributesToHighlight) {
      algoliaQueryObject.attributesToHighlight = attributesToHighlight;
    }

    let filterStrings = [];

    // Create string that filters on type and id (used for lists - different than completed/verified)
    const typeIDFilterString = this.createTypeIDFilterString(filters, page);
    if (typeIDFilterString && typeIDFilterString.length > 0) {
      filterStrings.push(typeIDFilterString);
    }

    // Note for below: Any filters that are joined with OR's need to have grouping
    // parens put around them
    // Any filters that are joined with AND's cannot have grouping parens around them
    // as algolia will error

    // Maps have a different set of fields and live in a different index, so certain filters do not apply to them
    // even if they live in the current filter state
    if (!this.isMapsQuery(typeIDFilterString)) {
      const trailFilterStrings = this.buildTrailFilterStrings({
        filters,
        page,
        completedIds,
        verifiedIds,
        isParksAssociatedTrailsFeatureFlag
      });

      filterStrings = filterStrings.concat(trailFilterStrings);
    }

    // The remaining filters can apply to both maps/tracks + trails

    // Create activity filters
    filterStrings.push(this.createAlgoliaMultFilterString(filters.activities, 'activities:', 'AND'));

    // Create length filters
    let lengthFilterString = '';
    if (filters.lengths) {
      if (filters.lengths.value[1] === -1) {
        lengthFilterString = `${'(length>='}${filters.lengths.value[0]})`;
      } else {
        lengthFilterString = `${'(length:'}${filters.lengths.value[0]} TO ${filters.lengths.value[1]})`;
      }
    }
    if (lengthFilterString.length > 0) {
      filterStrings.push(`(${lengthFilterString})`);
    }

    // Create elevation filters
    let elevationFilterString = '';
    if (filters.elevation_gain) {
      if (filters.elevation_gain.value[1] === -1) {
        elevationFilterString = `${'(elevation_gain>='}${filters.elevation_gain.value[0]})`;
      } else {
        elevationFilterString = `${'(elevation_gain:'}${filters.elevation_gain.value[0]} TO ${filters.elevation_gain.value[1]})`;
      }
    }
    if (elevationFilterString.length > 0) {
      filterStrings.push(`(${elevationFilterString})`);
    }

    // Create highest point filters
    let highestPointFilterString = '';
    if (filters.highest_point && filters.highest_point.value.toString() !== '0,-1') {
      if (filters.highest_point.value[1] === -1) {
        highestPointFilterString = `${'(highest_point>='}${filters.highest_point.value[0]})`;
      } else {
        highestPointFilterString = `${'(highest_point:'}${filters.highest_point.value[0]} TO ${filters.highest_point.value[1]})`;
      }
    }
    if (highestPointFilterString.length > 0) {
      filterStrings.push(`(${highestPointFilterString})`);
    }

    // Create minRating filter
    if (filters.minRating) {
      filterStrings.push(`avg_rating >= ${filters.minRating}`);
    }

    // Join the filterStrings with AND
    algoliaQueryObject.filters = filterStrings.filter(f => f.length > 0).join(' AND ');

    if (insidePolygon) {
      if (isMultiPolygon(insidePolygon)) {
        algoliaQueryObject.insidePolygon = normalizeMultiPolygonData(insidePolygon);
      } else {
        algoliaQueryObject.insidePolygon = insidePolygon;
      }
    } else {
      // Set insideBoundingBox field on algoliaQueryObject
      const boundingBox = this.getBoundingBox(filters, page);
      if (boundingBox) {
        algoliaQueryObject.insideBoundingBox = boundingBox;
      }
    }

    return algoliaQueryObject;
  },
  buildTrailFilterStrings({ filters, page, completedIds, verifiedIds, isParksAssociatedTrailsFeatureFlag }) {
    const filterStrings = [];

    // Create filters based on not-completed, completed, verified
    const completedFilterString = this.getAlgoliaCompletedFilterString(filters, page, completedIds, verifiedIds);
    if (completedFilterString.length > 0) {
      filterStrings.push(`(${completedFilterString})`);
    }

    // Create difficulty rating filters
    const difficultyRatingFilterString = this.createAlgoliaMultFilterString(filters.difficulty, 'difficulty_rating:', 'OR');
    if (difficultyRatingFilterString.length > 0) {
      filterStrings.push(`(${difficultyRatingFilterString})`);
    }

    // Create route type filters
    const routeTypeFilterString = this.createAlgoliaMultFilterString(filters.route_type, 'route_type:', 'OR');
    if (routeTypeFilterString.length > 0) {
      filterStrings.push(`(${routeTypeFilterString})`);
    }

    // Create trail traffic filters
    const trailTrafficFilterString = this.createAlgoliaMultFilterString(filters.visitor_usage, 'visitor_usage:', 'OR');
    if (trailTrafficFilterString.length > 0) {
      filterStrings.push(`(${trailTrafficFilterString})`);
    }

    // Create feature filters
    filterStrings.push(this.createAlgoliaMultFilterString(filters.features, 'features:', 'AND'));

    // Create access filters (NOTE: access is under features in algolia, so we use features prefix)
    filterStrings.push(this.createAlgoliaMultFilterString(filters.access, 'features:', 'AND'));

    // Create country filters
    const countryFilterString = this.createAlgoliaMultFilterString(filters.countries, 'country_id=', 'OR');
    if (countryFilterString.length > 0) {
      filterStrings.push(`(${countryFilterString})`);
    }

    // Create state filters
    const stateFilterString = this.createAlgoliaMultFilterString(filters.states, 'state_id=', 'OR');
    if (stateFilterString.length > 0) {
      filterStrings.push(`(${stateFilterString})`);
    }

    // Create city filters
    const cityFilterString = this.createAlgoliaMultFilterString(filters.cities, 'city_id=', 'OR');
    if (cityFilterString.length > 0) {
      filterStrings.push(`(${cityFilterString})`);
    }

    // Create area filters
    const areaField = isParksAssociatedTrailsFeatureFlag ? 'associated_area_ids:' : 'area_id=';
    const areaFilterString = this.createAlgoliaMultFilterString(filters.areas, `${areaField}`, 'OR');
    if (areaFilterString.length > 0) {
      filterStrings.push(`(${areaFilterString})`);
    }

    // Create point of interest filters
    const poiFilterString = this.createAlgoliaMultFilterString(filters.poiIds || {}, 'poi_ids:', 'OR');
    if (poiFilterString.length > 0) {
      filterStrings.push(`(${poiFilterString})`);
    }

    // Create closed_status filters
    const closedStatusFilterString = this.createAlgoliaMultFilterString(filters.closed_status, 'is_closed:', 'OR');
    if (closedStatusFilterString.length > 0) {
      filterStrings.push(`(${closedStatusFilterString})`);
    }

    // Note that this filter triggers specific business logic around how we filter
    // results based on reviews and created_at. Be wary about expanding the scope of this and
    // note how this field is set. Holistically we probably need a better mechanism
    // for settings business logic like this. If we ever exposed these filters in the UI
    // we could end up with conflicting logic.
    if (filters.isMinimumViableContent) {
      filterStrings.push(`created_at < ${Math.floor(Date.now() / 1000) - TwoWeeksInSeconds}`);
      filterStrings.push('(num_reviews >= 2 OR num_text_reviews >= 1)');
    }

    if ('isClosed' in filters) {
      filterStrings.push(`is_closed:${filters.isClosed}`);
    }

    if ('isPrivateProperty' in filters) {
      filterStrings.push(`is_private_property:${filters.isPrivateProperty}`);
    }

    // Create has_profile_photo filter
    if (filters.has_profile_photo) {
      filterStrings.push('has_profile_photo:true');
    }

    return filterStrings;
  },
  createAlgoliaLocQueryObject(types, filters, mobileWidth, page) {
    const algoliaQueryObject = {
      hitsPerPage: 200,
      filters: types.map(type => `type:${type}`).join(' OR ')
    };

    // Set insideBoundingBox field on algoliaQueryObject
    const boundingBox = this.getBoundingBox(filters, page);
    if (boundingBox) {
      algoliaQueryObject.insideBoundingBox = boundingBox;
    }

    return algoliaQueryObject;
  },
  createAlgoliaTrailsObject(map) {
    return {
      hitsPerPage: 5000,
      insideBoundingBox: lngLatBoundsToAlgoliaBounds(map),
      facetFilters: ['type:trail'],
      attributesToRetrieve: CARD_ATTRIBUTES
    };
  },
  getBoundingBox(filters, page) {
    // if were zoomed out far enough on certain pages, we just want all the results from the other filters, ignore bounding box
    if (page === PageStrings.EXPLORE_FAVORITE_PAGE || page === PageStrings.EXPLORE_CUSTOM_PAGE || page === PageStrings.EXPLORE_COMPLETED_PAGE) {
      if (filters.zoomLevel != null && filters.zoomLevel < 4) {
        return null;
      }
    }
    // otherwise, find bounding box
    if (filters.boundingBox != null && (filters.zoomLevel == null || (filters.zoomLevel && filters.zoomLevel >= 3))) {
      let boundingBox;
      if (filters.boundingBox.longitudeTopLeft > filters.boundingBox.longitudeBottomRight) {
        boundingBox = [
          filters.boundingBox.latitudeTopLeft,
          filters.boundingBox.longitudeTopLeft,
          filters.boundingBox.latitudeBottomRight,
          180,
          filters.boundingBox.latitudeTopLeft,
          -179.999,
          filters.boundingBox.latitudeBottomRight,
          filters.boundingBox.longitudeBottomRight
        ].join(',');
      } else {
        boundingBox = [
          filters.boundingBox.latitudeTopLeft,
          filters.boundingBox.longitudeTopLeft,
          filters.boundingBox.latitudeBottomRight,
          filters.boundingBox.longitudeBottomRight
        ].join(',');
      }
      return boundingBox;
    }
    return null;
  },
  filtersAreActive(filters) {
    return (
      !!filters.minRating ||
      !!filters.queryTerm ||
      this.hasFiltersSet(filters.completed) ||
      this.hasFiltersSet(filters.linked) ||
      this.hasFiltersSet(filters.difficulty) ||
      this.hasFiltersSet(filters.route_type) ||
      this.hasFiltersSet(filters.visitor_usage) ||
      this.hasFiltersSet(filters.activities) ||
      this.hasFiltersSet(filters.features) ||
      this.hasFiltersSet(filters.access) ||
      this.hasFiltersSet(filters.areas) ||
      this.hasFiltersSet(filters.cities) ||
      this.hasFiltersSet(filters.states) ||
      this.hasFiltersSet(filters.countries) ||
      this.hasAlteredRangeFilter(filters.lengths) ||
      this.hasAlteredRangeFilter(filters.elevation_gain) ||
      this.hasAlteredRangeFilter(filters.highest_point) ||
      this.hasAlteredSliderFilter(filters.distance_away)
    );
  },
  hasFiltersSet(filters) {
    return Object.keys(filters).filter(key => filters[key].selected).length > 0;
  },
  hasAlteredSliderFilter(filter) {
    return filter.value !== -1;
  },
  hasAlteredRangeFilter(filter) {
    return filter && (filter.value[0] !== 0 || filter.value[1] !== -1);
  },
  getNumSelectedFilters(filters) {
    return Object.keys(filters).reduce((numSelected, key) => {
      const filter = filters[key];
      switch (key) {
        case 'minRating':
        case 'queryTerm':
          return filter !== null ? numSelected + 1 : numSelected;
        case 'completed':
        case 'linked':
        case 'difficulty':
        case 'route_type':
        case 'visitor_usage':
        case 'activities':
        case 'features':
        case 'access':
        case 'areas':
        case 'cities':
        case 'states':
        case 'countries':
          return numSelected + Object.keys(filter).reduce((acc, filterKey) => (filter[filterKey].selected ? acc + 1 : acc), 0);
        case 'lengths':
        case 'elevation_gain':
          return this.hasAlteredRangeFilter(filter) ? numSelected + 1 : numSelected;
        default:
          return numSelected;
      }
    }, 0);
  },
  getSelectedSortFilter(filters) {
    let selectedSort = null;
    Object.keys(filters.sort).forEach(sortKey => {
      const sortFilter = filters.sort[sortKey];
      if (sortFilter?.selected) {
        selectedSort = sortKey;
      }
    });
    return selectedSort;
  },
  getSelectedCloudFilters(cloudFilter) {
    return Object.keys(cloudFilter)
      .map(cloudFilterKey => {
        const cloudFilterOption = cloudFilter[cloudFilterKey];
        if (cloudFilterOption?.selected) {
          return cloudFilterKey;
        }
        return null;
      })
      .filter(item => !!item);
  },
  getSelectedActivityFilterValues(filters) {
    return this.getSelectedCloudFilters(filters.activities);
  },
  getSelectedAttractionsFilterValues(filters) {
    return this.getSelectedCloudFilters(filters.features);
  },
  getSelectedCompletedFilterValues(filters) {
    return this.getSelectedCloudFilters(filters.completed);
  },
  getSelectedDifficultyFilterValues(filters) {
    return this.getSelectedCloudFilters(filters.difficulty);
  },
  getSelectedRouteFilterValues(filters) {
    return this.getSelectedCloudFilters(filters.route_type);
  },
  getSelectedSuitabilityFilterValues(filters) {
    return this.getSelectedCloudFilters(filters.access);
  },
  getSelectedTrailTrafficFilterValues(filters) {
    return this.getSelectedCloudFilters(filters.visitor_usage);
  },
  addUrlParamFromSingleFilter(urlParams, filter, paramKey) {
    if (!filter) {
      return;
    }
    urlParams.push(`${paramKey}=${filter}`);
  },
  addUrlParamFromMultiFilter(urlParams, filter, paramKey) {
    if (!filter) {
      return;
    }
    Object.keys(filter).forEach(key => {
      if (filter[key].selected) {
        urlParams.push(`${paramKey}[]=${key}`);
      }
    });
  },
  addUrlParamFromRangeFilter(urlParams, filter, paramKey) {
    if (!filter || isEqual(filter.value, [0, -1])) {
      return;
    }

    urlParams.push(`${paramKey}[]=${filter.value[0]}`);
    urlParams.push(`${paramKey}[]=${filter.value[1]}`);
  },
  addUrlParamFromSliderFilter(urlParams, filter, paramKey) {
    if (!filter || filter.value === -1) {
      return;
    }

    urlParams.push(`${paramKey}=${filter.value}`);
  },
  addUtmParams(urlParams, value, paramKey) {
    if (!paramKey || !value) {
      return;
    }

    urlParams.push(`${paramKey}=${value}`);
  },
  createUrlFromPageAndFilters(page, filters, languageRegionCode, selectedObject, utmAttribution) {
    const urlParams = [];

    // If not explore or no location object or location object is not a city/park (slug split < 3 ie no country,state,city)
    if (filters.boundingBox && page !== PageStrings.EXPLORE_LIFELINE_PAGE) {
      urlParams.push([
        `b_tl_lat=${filters.boundingBox.latitudeTopLeft}`,
        `b_tl_lng=${filters.boundingBox.longitudeTopLeft}`,
        `b_br_lat=${filters.boundingBox.latitudeBottomRight}`,
        `b_br_lng=${filters.boundingBox.longitudeBottomRight}`
      ]);
    }

    // if there are UTM attributions in the query string params add them to the url
    if (utmAttribution) {
      for (let i = 0; i < utmAttribution.length; i++) {
        this.addUtmParams(urlParams, utmAttribution[i]?.value, utmAttribution[i]?.paramKey);
      }
    }
    this.addUrlParamFromSingleFilter(urlParams, page === PageStrings.EXPLORE_COMMUNITY_CONTENT_PAGE, 'cc');
    this.addUrlParamFromSingleFilter(urlParams, filters.queryTerm, 'q');
    this.addUrlParamFromSingleFilter(urlParams, filters.mobileMap, 'mobileMap');
    this.addUrlParamFromSingleFilter(urlParams, filters.minRating, 'rating_min');

    this.addUrlParamFromMultiFilter(urlParams, filters.completed, 'c');
    this.addUrlParamFromMultiFilter(urlParams, filters.activities, 'a');
    this.addUrlParamFromMultiFilter(urlParams, filters.features, 'f');
    this.addUrlParamFromMultiFilter(urlParams, filters.access, 'f');
    this.addUrlParamFromMultiFilter(urlParams, filters.areas, 'ar');
    this.addUrlParamFromMultiFilter(urlParams, filters.difficulty, 'diff');
    this.addUrlParamFromMultiFilter(urlParams, filters.route_type, 'route');
    this.addUrlParamFromMultiFilter(urlParams, filters.visitor_usage, 'usage');

    this.addUrlParamFromSliderFilter(urlParams, filters.distance_away, 'distance_away');
    this.addUrlParamFromRangeFilter(urlParams, filters.elevation_gain, 'elev');
    this.addUrlParamFromRangeFilter(urlParams, filters.highest_point, 'highest_point');
    this.addUrlParamFromRangeFilter(urlParams, filters.lengths, 'length');

    let extraFilters = urlParams.reduce((acc, el) => acc.concat(el), []).join('&'); // crossbrowser safe version of .flat()
    let path = '/explore';
    if (
      page === PageStrings.EXPLORE_ALL_PAGE &&
      filters.locationObject &&
      filters.locationObject.slug &&
      filters.locationObject.slug.split('/').length > 2
    ) {
      path += `/${filters.locationObject.slug}`;
    } else if (page === PageStrings.EXPLORE_ALL_PAGE) {
      path = '/explore';
    } else if (
      page === PageStrings.EXPLORE_FAVORITE_PAGE ||
      page === PageStrings.EXPLORE_COMPLETED_PAGE ||
      page === PageStrings.EXPLORE_CUSTOM_PAGE ||
      page === PageStrings.EXPLORE_LIFELINE_PAGE ||
      page === PageStrings.EXPLORE_PENDING_PAGE
    ) {
      path = typeof window !== 'undefined' ? window.location.pathname : '/explore';
    } else if (page === PageStrings.EXPLORE_USERS_TRACKS_PAGE) {
      if (filters.initialUserSlug) path = `/explore/members/${filters.initialUserSlug}/recordings`;
    } else if (page === PageStrings.EXPLORE_TRAIL_MAP_PAGE) {
      path = `/explore/${selectedObject.slug}`;
      const u = getQueryStringValue('u');
      if (u === 'm' || u === 'i') {
        path += `?u=${u}`;
      }
      extraFilters = [];
    } else if (page === PageStrings.EXPLORE_USERS_TRACKS_MAP_PAGE) {
      path = typeof window !== 'undefined' ? window.location.pathname : '/explore';
      const u = getQueryStringValue('u');
      if (u === 'm' || u === 'i') {
        path += `?u=${u}`;
      }
      extraFilters = [];
    } else if (page === PageStrings.EXPLORE_USERS_MAPS_PAGE) {
      if (filters.initialUserSlug) path = `/members/${filters.initialUserSlug}/maps`;
    } else if (page === PageStrings.EXPLORE_USERS_MAPS_MAP_PAGE) {
      path = `/explore/map/${selectedObject.slug}`;
      extraFilters = [];
    }
    if (extraFilters.length > 0 && typeof window !== 'undefined') {
      return LanguageSupportUtil.wrapUrlSafe(`${path}?${extraFilters}`, languageRegionCode);
    }
    if (typeof window !== 'undefined') {
      return LanguageSupportUtil.wrapUrlSafe(path, languageRegionCode);
    }
    return path;
  },
  customSearch(items, filters, context, isMobileWidth, isListPage, completedIds, verifiedIds) {
    let results = JSON.parse(JSON.stringify(items)) || [];

    if (filters.boundingBox && results) {
      const checkInBoundingBox = (lat, lng) => {
        // Super zoomed out, just show em all
        if (filters.zoomLevel != null && filters.zoomLevel < 4) return true;
        if (filters.boundingBox.longitudeTopLeft > filters.boundingBox.longitudeBottomRight) {
          if (
            lat < parseFloat(filters.boundingBox.latitudeTopLeft) &&
            lat > parseFloat(filters.boundingBox.latitudeBottomRight) &&
            ((lng > parseFloat(filters.boundingBox.longitudeTopLeft) && lng < 180) ||
              (lng < parseFloat(filters.boundingBox.longitudeBottomRight) && lng > -180))
          )
            return true;
        } else if (
          lat < parseFloat(filters.boundingBox.latitudeTopLeft) &&
          lat > parseFloat(filters.boundingBox.latitudeBottomRight) &&
          lng < parseFloat(filters.boundingBox.longitudeBottomRight) &&
          lng > parseFloat(filters.boundingBox.longitudeTopLeft)
        ) {
          return true;
        }
        return false;
      };
      results = results.filter(item => {
        const lat = parseFloat(item._geoloc.lat);
        const lng = parseFloat(item._geoloc.lng);
        return checkInBoundingBox(lat, lng);
      });
    }
    if (filters.minRating && results) {
      if (isListPage) {
        results = results.filter(item => item.avg_rating >= filters.minRating);
      } else {
        results = results.filter(item => item.rating >= filters.minRating);
      }
    }
    const selectedActivityFilterValues = this.getSelectedActivityFilterValues(filters);
    if (selectedActivityFilterValues.length > 0 && results) {
      if (isListPage) {
        results = results.filter(item => item.activities?.some(activity => selectedActivityFilterValues.includes(activity)));
      } else {
        results = results.filter(item => item.activity && selectedActivityFilterValues.indexOf(item.activity.uid) >= 0);
      }
    }

    let minLength = null;
    let maxLength = null;
    if (filters.lengths) {
      if (filters.lengths.value[1] !== -1) {
        maxLength = filters.lengths.value[1];
      }
      if (filters.lengths.value[0] > 0) {
        minLength = filters.lengths.value[0];
      }
    }

    let minElevationGain = null;
    let maxElevationGain = null;
    if (filters.elevation_gain) {
      if (filters.elevation_gain.value[1] !== -1) {
        maxElevationGain = filters.elevation_gain.value[1];
      }
      if (filters.elevation_gain.value[0] > 0) {
        minElevationGain = filters.elevation_gain.value[0];
      }
    }

    if (filters.linked) {
      // set value of 'selected'
      let selected = null;
      if (filters.linked.unlinked.selected) {
        selected = 'unlinked';
      }
      if (filters.linked.linked.selected) {
        if (selected === 'unlinked') {
          selected = null;
        } else {
          selected = 'linked';
        }
      }
      // use value of 'selected' to determine how to filter items by trail_id
      if (selected && results) {
        results = results.filter(item => {
          if (selected === 'unlinked') {
            return !item.trail_id && !item.private; // show non-linked, public results
          }
          if (selected === 'linked') {
            return !!item.trail_id;
          }
          return false;
        });
      }
    }

    if (isListPage) {
      results = results.filter(item => {
        if (maxLength == null && minLength == null) return true;
        if (maxLength == null && item.length > minLength) return true;
        if (minLength == null && item.length < maxLength) return true;
        if (maxLength < 0 && item.length > minLength) return true;
        if (item.length < maxLength && item.length > minLength) return true;
        return false;
      });
    } else {
      results = results.filter(item => {
        if (maxLength == null && minLength == null) return true;
        if (maxLength == null && item.distance > minLength) return true;
        if (minLength == null && item.distance < maxLength) return true;
        if (maxLength < 0 && item.distance > minLength) return true;
        if (item.distance < maxLength && item.distance > minLength) return true;
        return false;
      });
    }

    if (isListPage) {
      const selectedDifficultyValues = this.getSelectedDifficultyFilterValues(filters);
      const selectedSuitabilityValues = this.getSelectedSuitabilityFilterValues(filters);
      const selectedAttractionValues = this.getSelectedAttractionsFilterValues(filters);
      const selectedRouteTypeValues = this.getSelectedRouteFilterValues(filters);
      const selectedVisitorUsageValues = this.getSelectedTrailTrafficFilterValues(filters);
      const selectedTrailCompletionValues = this.getSelectedCompletedFilterValues(filters);
      const selectedClosedValues = this.getSelectedCloudFilters(filters.closed_status);
      if (selectedDifficultyValues.length > 0 && results) {
        const difficultyMap = {
          easy: '1',
          moderate: '3',
          hard: '5'
        };
        const difficulty = selectedDifficultyValues.map(value => difficultyMap[value]);
        results = results.filter(item => item?.difficulty_rating && difficulty.indexOf(item?.difficulty_rating) >= 0);
      }
      if (selectedSuitabilityValues.length > 0 && results) {
        results = results.filter(item => item.features?.some(feature => selectedSuitabilityValues.includes(feature)));
      }
      if (selectedAttractionValues.length > 0 && results) {
        results = results.filter(item => item.features?.some(feature => selectedAttractionValues.includes(feature)));
      }
      if (selectedRouteTypeValues.length > 0 && results) {
        results = results.filter(item => item.route_type && selectedRouteTypeValues.indexOf(item.route_type) >= 0);
      }
      if (selectedVisitorUsageValues.length > 0 && results) {
        results = results.filter(item => item.visitor_usage && selectedVisitorUsageValues.indexOf(item.visitor_usage) >= 0);
      }
      if (selectedTrailCompletionValues.length > 0 && results) {
        results = results.filter(item => {
          const id = item.ID.toString();
          if (selectedTrailCompletionValues.includes('completed') && completedIds?.includes(id)) {
            return true;
          }
          if (selectedTrailCompletionValues.includes('verified_completed') && verifiedIds?.includes(id)) {
            return true;
          }
          if (selectedTrailCompletionValues.includes('not_completed') && !completedIds?.includes(id) && !verifiedIds?.includes(id)) {
            return true;
          }
          return false;
        });
      }
      if (selectedClosedValues.length > 0 && results) {
        results = results.filter(item => {
          if (selectedClosedValues.includes('closed') && item.is_closed) {
            return true;
          }
          if (selectedClosedValues.includes('open') && !item.is_closed) {
            return true;
          }
          return false;
        });
      }
    }

    results = results.filter(item => {
      if (maxElevationGain == null && minElevationGain == null) return true;
      if (maxElevationGain == null && item.elevation_gain > minElevationGain) return true;
      if (minElevationGain == null && item.elevation_gain < maxElevationGain) return true;
      if (maxElevationGain < 0 && item.elevation_gain > minElevationGain) return true;
      if (item.elevation_gain < maxElevationGain && item.elevation_gain > minElevationGain) return true;
      return false;
    });

    if (filters.queryTerm && filters.queryTerm.length > 0) {
      results = results.filter(item => {
        const name = item.name ? item.name.toLowerCase() : '';
        const dateStr = new Date(item.created_at)
          .toLocaleDateString(context.languageRegionCode, {
            weekday: 'long',
            year: 'numeric',
            month: 'long',
            day: 'numeric'
          })
          .toLowerCase();
        const query = filters.queryTerm.toLowerCase();
        return `${name} ${dateStr}`.search(query) >= 0;
      });
    }
    const selectedFilterKey = Object.keys(filters.sort).filter(itemKey => filters.sort[itemKey].selected)[0];

    if (selectedFilterKey) {
      if (selectedFilterKey === 'date') {
        results = results.sort((itemA, itemB) => {
          const dateA = new Date(itemA.created_at);
          const dateB = new Date(itemB.created_at);
          if (filters.sort[selectedFilterKey].value === -1) return dateA - dateB;
          return dateB - dateA;
        });
      } else if (selectedFilterKey === 'name') {
        results = results.sort((itemA, itemB) => {
          const nameA = itemA?.name?.toUpperCase() || '';
          const nameB = itemB?.name?.toUpperCase() || '';
          if (filters.sort[selectedFilterKey].value === 1) {
            if (nameA > nameB) return 1;
            if (nameA < nameB) return -1;
            return 0;
          }

          if (nameA < nameB) return 1;
          if (nameA > nameB) return -1;
          return 0;
        });
      } else if (selectedFilterKey === 'recently-added') {
        results = results.sort((itemA, itemB) => {
          const dateA = new Date(itemA.updated);
          const dateB = new Date(itemB.updated);
          if (filters.sort[selectedFilterKey].value === -1) return dateA - dateB;
          return dateB - dateA;
        });
      }
    }

    return results;
  },
  isMapsQuery(typeIDFilterString) {
    return `${typeIDFilterString}`.match('type:(track|map)');
  }
};

export { SearchFiltersUtil };
