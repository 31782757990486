// extracted by mini-css-extract-plugin
export var button = "styles-module__button___M99Bs";
export var close = "styles-module__close___uf0Ax";
export var container = "styles-module__container___tmd7I";
export var contentContainer = "styles-module__contentContainer___TX2gS";
export var divider = "styles-module__divider___pWEbb";
export var header = "styles-module__header___BKLB7";
export var imageContainer = "styles-module__imageContainer___svl7i";
export var logoAndRatingContainer = "styles-module__logoAndRatingContainer___ZBmGD";
export var nameAndRatingContainer = "styles-module__nameAndRatingContainer___leC59";
export var phone = "styles-module__phone___vgtf1";
export var ratingContainer = "styles-module__ratingContainer___rUWSw";
export var texture = "styles-module__texture___QbAId";