import { ReactNode, useState } from 'react';
import { FormattedMessage } from '@alltrails/shared/react-intl';
import { CardElementState } from '@recurly/react-recurly';
import type { RecurlyError } from '@recurly/recurly-js';
import { hasValidLastName, isValidPostalCode } from '../../utils';
import { CARD_RECURLY_IDs, CARD_VALIDATION_NAMES } from '../../types';

export type TokenValidationErrors = {
  nameError: ReactNode;
  postalCodeError: ReactNode;
  ccInputStateError: ReactNode;
};

export default function useTokenizedFormValidation(hasInitialName = false) {
  const [nameError, setNameError] = useState<ReactNode>('');
  const [postalCodeError, setPostalCodeError] = useState<ReactNode>('');
  const [ccInputStateError, setCCInputStateError] = useState<ReactNode>('');
  const [didInputPostalCode, setDidInputPostalCode] = useState(false);
  const [didInputName, setDidInputName] = useState(hasInitialName);
  const [didInputCC, setDidInputCC] = useState(false);

  /* Error messages for client-side input validation */
  const setClientValidationErrors = (
    inputName: CARD_VALIDATION_NAMES,
    value: string | { number: CardElementState; expiry: CardElementState; cvv: CardElementState; empty?: boolean }
  ) => {
    if (inputName === CARD_VALIDATION_NAMES.POSTAL_CODE && !didInputPostalCode) {
      setDidInputPostalCode(true);
    }

    if (inputName === CARD_VALIDATION_NAMES.NAME && !didInputName) {
      setDidInputName(true);
    }

    if (inputName === CARD_VALIDATION_NAMES.CREDIT_CARD && typeof value === 'object') {
      if (!didInputCC && !value.empty) {
        setDidInputCC(true);
      }
      // Recurly provides a "input state" object for the fields controlled by their iframe form.
      const { number, expiry, cvv } = value;

      // Don't show an error if the user hasn't input anything yet, or if the user focuses on any field.
      if ((!didInputCC && number.empty && expiry.empty && cvv.empty) || number.focus || expiry.focus || cvv.focus) {
        setCCInputStateError('');
      } else if (!number.valid) {
        setCCInputStateError(<FormattedMessage defaultMessage="Invalid card number" />);
      } else if (!expiry.valid) {
        setCCInputStateError(<FormattedMessage defaultMessage={"Your card's expiration date is invalid."} />);
      } else if (!cvv.valid) {
        setCCInputStateError(<FormattedMessage defaultMessage="Enter CVV Code" />);
      } else if (ccInputStateError) {
        setCCInputStateError('');
      }
    }

    if (typeof value === 'string') {
      if (inputName === CARD_VALIDATION_NAMES.NAME) {
        let error: ReactNode | string = '';
        if (value.length < 1) {
          error = <FormattedMessage defaultMessage="Please enter the cardholder's name." />;
        } else if (!hasValidLastName(value)) {
          error = <FormattedMessage defaultMessage="Cardholder's name must include a valid last name." />;
        }
        setNameError(error);
      }

      if (inputName === CARD_VALIDATION_NAMES.POSTAL_CODE) {
        const error = !isValidPostalCode(value) ? <FormattedMessage defaultMessage="Invalid postal code" /> : '';
        setPostalCodeError(error);
      }
    }
  };

  /* Error messages after requesting a Recurly token */
  const setTokenRequestErrors = (errorData?: RecurlyError) => {
    if (!errorData) {
      setCCInputStateError(<FormattedMessage defaultMessage="There was an error processing your payment. Please contact support." />);
      return;
    }

    const { details } = errorData;
    details.forEach(errorDetail => {
      if (errorDetail.field === CARD_RECURLY_IDs.NUMBER) {
        setCCInputStateError(<FormattedMessage defaultMessage="Invalid card number" />);
      }

      if (errorDetail.field === CARD_RECURLY_IDs.MONTH) {
        setCCInputStateError(<FormattedMessage defaultMessage={"Your card's expiration month is invalid."} />);
      }

      if (errorDetail.field === CARD_RECURLY_IDs.YEAR) {
        setCCInputStateError(<FormattedMessage defaultMessage={"Your card's expiration year is invalid."} />);
      }

      if (errorDetail.field === CARD_RECURLY_IDs.CVV) {
        setCCInputStateError(<FormattedMessage defaultMessage="Enter CVV Code" />);
      }

      if (errorDetail.field === CARD_RECURLY_IDs.FIRST_NAME) {
        setNameError(<FormattedMessage defaultMessage={"Cardholder's name must include a valid first name."} />);
      }

      if (errorDetail.field === CARD_RECURLY_IDs.LAST_NAME) {
        setNameError(<FormattedMessage defaultMessage={"Cardholder's name must include a valid last name."} />);
      }
    });

    if (!details.some(({ field }) => Object.values(CARD_RECURLY_IDs).includes(field as CARD_RECURLY_IDs))) {
      setCCInputStateError(<FormattedMessage defaultMessage="There was an error processing your payment. Please contact support." />);
    }
  };

  const hasValidationErrors = () => {
    let hasEmptyInput = false;
    if (!didInputName) {
      setNameError(<FormattedMessage defaultMessage="Please enter the cardholder's name." />);
      hasEmptyInput = true;
    }

    if (!didInputPostalCode) {
      setPostalCodeError(<FormattedMessage defaultMessage="Invalid postal code" />);
      hasEmptyInput = true;
    }

    if (!didInputCC) {
      setCCInputStateError(<FormattedMessage defaultMessage="Invalid card number" />);
      hasEmptyInput = true;
    }

    return nameError || postalCodeError || ccInputStateError || hasEmptyInput;
  };

  const validationErrors = {
    nameError,
    postalCodeError,
    ccInputStateError
  };

  return { setClientValidationErrors, setTokenRequestErrors, validationErrors, hasValidationErrors };
}
