import Coupon from 'types/Coupon';
import Plan from 'types/Plan';

export function isTrial(plan: Plan, coupon?: Coupon) {
  const recurlyPlans = [
    'alltrails_pro_annual_30_7trial',
    'alltrails_pro_annual_36_7trial',
    'alltrails_pro_annual_20_7trial',
    'alltrails_pro_annual_24_7trial',
    'alltrails_pro_annual_10_7trial',
    'alltrails_pro_annual_21_7trial'
  ];
  return recurlyPlans.includes(plan.recurlyPlanCode) || coupon?.type === 'FreeTrialCoupon';
}

export function getPriceTotal(plan: Plan, coupon?: Coupon) {
  if (coupon && coupon.type !== 'FreeTrialCoupon' && coupon.discountPercentage) {
    // Recurly only allows integer based discount percentages but we really want 1/3 off when we say 33% or 34%
    const discountPercentage = coupon.discountPercentage === 33 || coupon.discountPercentage === 34 ? 100 / 3 : coupon.discountPercentage || 0;

    // make sure we trunc so $29.99 * 50% off = $14.99 and not $14.995 rounded to $15
    return Math.trunc(Number(plan.priceTotal) * (100 - discountPercentage)) / 100;
  }
  if (coupon && coupon.type === 'SubscriptionCoupon' && coupon.discountedPricePerYear) {
    // This is a fixed discount coupon

    return Number(coupon.discountedPricePerYear);
  }

  return Number(plan.priceTotal);
}

export function getDiscountPercentage(plan: Plan, coupon?: Coupon) {
  if (coupon && coupon.discountPercentage) {
    return coupon.discountPercentage;
  }

  if (coupon && coupon.type === 'SubscriptionCoupon' && coupon.discountedPricePerYear) {
    // Fixed discount coupons have a discounted price per year, but no discount percentage, so need to calculate it.

    return Math.round(((Number(plan.priceTotal) - Number(coupon.discountedPricePerYear)) / Number(plan.priceTotal)) * 100);
  }

  return 0;
}
