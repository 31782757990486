import Plan from 'types/Plan';
import CurrencyCode from 'types/CurrencyCode';
import { MEDIA_QUERY_SMALL_DOWN } from 'utils/constants/breakpoints';
import * as styles from './GiftLandingPage.module.scss';
import GiftingHeader from '../GiftingHeader';
import GiftValuePanel from '../GiftValuePanel';
import FrequentlyAskedQuestions from '../FrequentlyAskedQuestions';
import OnePercentForThePlanet from '../OnePercentForThePlanet';
import GiveAllTrailsBanner from '../GiveAllTrailsBanner';
import overlook2800Avif from './scenic-overlook-2800w.avif';
import overlook2800Jpg from './scenic-overlook-2800w.jpg';
import overlook2800Webp from './scenic-overlook-2800w.webp';
import overlook768Avif from './scenic-overlook-768w.avif';
import overlook768Jpg from './scenic-overlook-768w.jpg';
import overlook768Webp from './scenic-overlook-768w.webp';

type Props = {
  currencyCode: CurrencyCode;
  plan: Plan;
};

const GiftLandingPage = ({ currencyCode, plan }: Props) => {
  const scrollToTop = () => {
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
  };

  return (
    <>
      <GiftingHeader currencyCode={currencyCode} plan={plan} />
      <GiftValuePanel />
      <FrequentlyAskedQuestions />
      <OnePercentForThePlanet />
      <GiveAllTrailsBanner handleClick={scrollToTop} />
      <picture>
        <source media={MEDIA_QUERY_SMALL_DOWN} srcSet={overlook768Avif} type="image/avif" />
        <source media={MEDIA_QUERY_SMALL_DOWN} srcSet={overlook768Webp} type="image/webp" />
        <source media={MEDIA_QUERY_SMALL_DOWN} srcSet={overlook768Jpg} type="image/jpg" />
        <source srcSet={overlook2800Avif} type="image/avif" />
        <source srcSet={overlook2800Webp} type="image/webp" />
        <img alt="" className={styles.img} loading="lazy" src={overlook2800Jpg} />
      </picture>
    </>
  );
};

export default GiftLandingPage;
