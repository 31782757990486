import { useContext } from 'react';
import { useIntl } from '@alltrails/shared/react-intl';
import SearchOrigin from '@alltrails/analytics/enums/SearchOrigin';
import { AlgoliaSearchBox } from '@alltrails/modules/AlgoliaSearch';
import '@alltrails/modules/AlgoliaSearch/index.css';
import { SearchInsightsContext } from 'components/SearchInsightsProvider';
import useUser from 'hooks/useUser';
import useExperiment from 'hooks/useExperiment';
import useLanguageRegionCode from '@alltrails/shared/hooks/useLanguageRegionCode';
import CustomProvider from 'components/CustomProvider';
import * as styles from './HomeSearchBar.module.scss';

const BaseHomeSearchBar = () => {
  const intl = useIntl();
  const languageRegionCode = useLanguageRegionCode();
  const user = useUser();
  const useNewSearch = useExperiment('web-disco-rm-global-nav')?.value === 'treatment_b';

  const { updateSearchId, logClickEvent } = useContext(SearchInsightsContext);

  return (
    <div className={styles.container}>
      <AlgoliaSearchBox
        configs={global.__AT_DATA__.algoliaConfig}
        isMobileBrowser={global.__AT_DATA__.mobileBrowser}
        languageRegionCode={languageRegionCode}
        navigateOnSelect
        onResultSelect={(result, additionalInfo) => {
          if (result && additionalInfo) {
            logClickEvent(result.objectID, additionalInfo.index + 1);
          }
        }}
        onSearch={({ queryID }) => {
          if (queryID) {
            updateSearchId(queryID);
          }
        }}
        placeholder={intl.formatMessage({ defaultMessage: 'Search by city, park, or trail name' })}
        searchOrigin={SearchOrigin.Homepage}
        searchTypes="all"
        size="homepage"
        testId="homepage-search-box"
        useNewSearch={useNewSearch}
        user={user}
      />
    </div>
  );
};

export default function HomeSearchBar() {
  return (
    <CustomProvider>
      <BaseHomeSearchBar />
    </CustomProvider>
  );
}
