import { wrapUrlSafe as wrapUrlSafeMonorepo, getLocale, generateNewLanguageUrl } from '@alltrails/shared/utils/languageSupport';
import LanguageRegionCode from '@alltrails/shared/types/LanguageRegionCode';
import { updateUserLanguageRequest } from '@alltrails/shared/utils/requests/me';
import type { User } from 'types/User';

function wrapUrlSafe(url: string, languageRegionCode: LanguageRegionCode) {
  // No action if URL is already language-adjusted (wrapUrlSafe is sometimes called twice)
  if (url && languageRegionCode !== LanguageRegionCode.English) {
    const matchRegex = new RegExp(`^\\/${getLocale(languageRegionCode)}\\/(.*)`);
    if (url.match(matchRegex)) {
      return url;
    }
  }
  return wrapUrlSafeMonorepo(url, languageRegionCode);
}

const redirectToNewLanguage = (newLanguage: LanguageRegionCode) => {
  if (!window) {
    return;
  }

  window.location.href = generateNewLanguageUrl(window.location.pathname, newLanguage);
};

const handleLanguageChange = (user: User, newLanguage: LanguageRegionCode) =>
  user
    ? updateUserLanguageRequest(newLanguage)
        .then(() => redirectToNewLanguage(newLanguage))
        .catch((err: Error) => console.error('err', err))
    : redirectToNewLanguage(newLanguage);

const LanguageSupportUtil = {
  // deprecated
  // Do not use LanguageSupportUtil.wrapUrlSafe. Import wrapUrlSafe from the module exports below.
  wrapUrlSafe
};

export default LanguageSupportUtil;
export { LanguageSupportUtil, wrapUrlSafe, handleLanguageChange };
