import useLanguageCode from '@alltrails/shared/hooks/useLanguageCode';
import { FormattedMessage } from '@alltrails/shared/react-intl';
import CustomProvider from 'components/CustomProvider';
import PlusBadge from '@alltrails/shared/denali/components/PlusBadge';
import getHelpCenterUrl from '@alltrails/shared/utils/constants/helpCenterUrl';
import useLanguageRegionCode from '@alltrails/shared/hooks/useLanguageRegionCode';
import noServiceNoProblem from '../../../assets/images/marketing/no-service-no-problem.jpg';
import offRouteBuzzer from '../../../assets/images/marketing/off-route-buzzer.jpg';
import exploringHasNeverBeenSoSafe from '../../../assets/images/marketing/exploring-has-never-been-so-safe.jpg';
import takeConditions from '../../../assets/images/marketing/take-conditions-out-of-the-equation.jpg';
import mapWeather from '../../../assets/images/marketing/map-weather.jpg';
import onePercentLogo from '../../../assets/images/partners-logos/1_Percent_Logo_@2x.png';
import iconNoAds from '../../../assets/images/marketing/icon-noads.png';
import noMoreAds from '../../../assets/images/marketing/no-more-ads.jpg';
import mapAirQuality from '../../../assets/images/marketing/map-air-quality.jpg';
import mapHeatmap from '../../../assets/images/marketing/map-heatmap.jpg';
import mapOverlayPollen from '../../../assets/images/marketing/map-overlay-pollen-pro.jpg';
import mapOverlayLightPollution from '../../../assets/images/marketing/map-overlay-light-pollution-pro.jpg';
import printCustomMaps from '../../../assets/images/marketing/print-custom-maps.jpg';
import iconPrint from '../../../assets/images/marketing/icon-print.png';

export default function ProMarketingFeatures() {
  const languageCode = useLanguageCode();
  const languageRegionCode = useLanguageRegionCode();

  return (
    <CustomProvider>
      <div id="marketing_features">
        <div id="marketing-features">
          <section className="textband" data-addscrollindicator>
            <div className="l-container">
              <h2 className="heading-1 p-dark">
                <span className="line-control line-control--intro-1">
                  <FormattedMessage defaultMessage="More adventures." />
                </span>{' '}
                <span className="line-control line-control--intro-2">
                  <FormattedMessage defaultMessage="More memories." />
                </span>
                <span className="line-control line-control--intro-3">
                  <FormattedMessage
                    defaultMessage="More with <pro></pro>."
                    values={{
                      pro: () => <PlusBadge size="xl" />
                    }}
                  />
                </span>
              </h2>
            </div>
          </section>
          <section className="feature">
            <div className="feature__banner banner lazyload" data-bg={noServiceNoProblem}>
              <div className="banner__text">
                <div className="l-container">
                  <h2 className="heading-1">
                    <span className="line-control">
                      <FormattedMessage defaultMessage="No service." />
                    </span>{' '}
                    <span className="line-control">
                      <FormattedMessage defaultMessage="No problem." />
                    </span>
                  </h2>
                </div>
              </div>
            </div>
            <div className="feature__main feature__main--centered l-container">
              <div className="feature__device">
                <div className="device__offset">
                  <picture>
                    <source
                      srcSet={`https://cdn-assets.alltrails.com/assets/images/pro_signup/pro-image-1-412w-${languageCode}.avif, https://cdn-assets.alltrails.com/assets/images/pro_signup/pro-image-1-824w-${languageCode}.avif 2x`}
                      type="image/avif"
                    />
                    <source
                      srcSet={`https://cdn-assets.alltrails.com/assets/images/pro_signup/pro-image-1-412w-${languageCode}.webp, https://cdn-assets.alltrails.com/assets/images/pro_signup/pro-image-1-824w-${languageCode}.webp 2x`}
                      type="image/webp"
                    />
                    <img
                      loading="lazy"
                      src={`https://cdn-assets.alltrails.com/assets/images/pro_signup/pro-image-1-412w-${languageCode}.png`}
                      alt="iPhone recoding a trail"
                    />
                  </picture>
                </div>
              </div>
              <div className="l-inner-container">
                <h3 className="feature__heading heading-3">
                  <FormattedMessage defaultMessage="Download maps to your phone and know where to go even without a signal." />
                </h3>
                <p>
                  <FormattedMessage defaultMessage="Explore new trails with confidence. See your exact GPS location on a fully detailed and interactive trail map. Stay connected even when you’re offline." />
                </p>
              </div>
            </div>
          </section>
          <section className="feature">
            <div className="feature__banner banner lazyload" data-bg={offRouteBuzzer}>
              <div className="banner__text">
                <div className="l-container">
                  <h2 className="heading-1">
                    <FormattedMessage defaultMessage="Stay on course" />
                  </h2>
                </div>
              </div>
            </div>
            <div className="feature__main feature__main--centered l-container">
              <div className="feature__device">
                <div className="device__offset">
                  <picture>
                    <source
                      srcSet={`https://cdn-assets.alltrails.com/assets/images/pro_signup/pro-image-2-412w-${languageCode}.avif, https://cdn-assets.alltrails.com/assets/images/pro_signup/pro-image-2-824w-${languageCode}.avif 2x`}
                      type="image/avif"
                    />
                    <source
                      srcSet={`https://cdn-assets.alltrails.com/assets/images/pro_signup/pro-image-2-412w-${languageCode}.webp, https://cdn-assets.alltrails.com/assets/images/pro_signup/pro-image-2-824w-${languageCode}.webp 2x`}
                      type="image/webp"
                    />
                    <img
                      alt="iPhone with off route alert"
                      loading="lazy"
                      src={`https://cdn-assets.alltrails.com/assets/images/pro_signup/pro-image-2-412w-${languageCode}.png`}
                      width="412"
                    />
                  </picture>
                </div>
              </div>
              <div className="l-inner-container">
                <h3 className="feature__heading heading-3">
                  <FormattedMessage defaultMessage="Never miss a turn with off-route notifications." />
                </h3>
                <p>
                  <FormattedMessage defaultMessage="Hit the trail with confidence knowing that if you wander off route, we’ll get you back on track." />
                </p>
              </div>
            </div>
          </section>
          <section className="feature">
            <div className="feature__banner banner lazyload" data-bg={exploringHasNeverBeenSoSafe}>
              <div className="banner__text">
                <div className="l-container">
                  <h2 className="heading-1">
                    <FormattedMessage defaultMessage="Exploring has never been so safe" />
                  </h2>
                </div>
              </div>
            </div>
            <div className="feature__main feature__main--centered l-container">
              <div className="feature__device">
                <div className="device__offset">
                  <picture>
                    <source
                      srcSet={`https://cdn-assets.alltrails.com/assets/images/pro_signup/pro-image-3-750w-${languageCode}.avif, https://cdn-assets.alltrails.com/assets/images/pro_signup/pro-image-3-1500w-${languageCode}.avif 2x`}
                      type="image/avif"
                    />
                    <source
                      srcSet={`https://cdn-assets.alltrails.com/assets/images/pro_signup/pro-image-3-750w-${languageCode}.webp, https://cdn-assets.alltrails.com/assets/images/pro_signup/pro-image-3-1500w-${languageCode}.webp 2x`}
                      type="image/webp"
                    />
                    <img
                      alt="iPhone with Lifeline"
                      loading="lazy"
                      src={`https://cdn-assets.alltrails.com/assets/images/pro_signup/pro-image-3-750w-${languageCode}.png`}
                      width="750"
                    />
                  </picture>
                </div>
              </div>
              <div className="l-inner-container">
                <h3 className="feature__heading heading-3">
                  <FormattedMessage defaultMessage="Lifeline: The real-time tracker that keeps you and your loved ones connected." />
                </h3>
                <p>
                  <FormattedMessage
                    defaultMessage="Designed for all outdoor enthusiasts, Lifeline helps you share trip details like planned start/finish time and a map of your planned route. Send status updates to your designated safety contacts with a tap. Most importantly, if you're overdue for your planned finish time, Lifeline will automatically send an alert to your safety contacts along with your last known location (<a>More Info</a>)"
                    values={{
                      a: (chunks: string) => (
                        <a href={getHelpCenterUrl(languageRegionCode, 360019246351)} target="_blank">
                          {chunks}
                        </a>
                      )
                    }}
                  />
                </p>
              </div>
            </div>
          </section>
          <section className="feature">
            <div className="feature__banner banner lazyload" data-bg={takeConditions}>
              <div className="banner__text">
                <div className="l-container">
                  <h2 className="heading-1">
                    <FormattedMessage defaultMessage="Take conditions out of the equation" />
                  </h2>
                </div>
              </div>
            </div>
            <div className="feature__main feature__main--centered l-container devicemask__interactive">
              <div className="feature__device">
                <div className="devicemask device__offset">
                  <picture>
                    <source
                      srcSet="https://cdn-assets.alltrails.com/assets/images/pro_signup/pro-map-base-412w.avif, https://cdn-assets.alltrails.com/assets/images/pro_signup/pro-map-base-824w.avif 2x"
                      type="image/avif"
                    />
                    <source
                      srcSet="https://cdn-assets.alltrails.com/assets/images/pro_signup/pro-map-base-412w.webp, https://cdn-assets.alltrails.com/assets/images/pro_signup/pro-map-base-824w.webp 2x"
                      type="image/webp"
                    />
                    <img
                      alt="iPhone frame bezel"
                      className="devicemask__frame"
                      loading="lazy"
                      src="https://cdn-assets.alltrails.com/assets/images/pro_signup/pro-map-base-412w.png"
                      width="412"
                      height="594"
                    />
                  </picture>
                  <div className="devicemask__screens">
                    <picture>
                      <source
                        srcSet={`https://cdn-assets.alltrails.com/assets/images/pro_signup/pro-map-weather-234w-${languageCode}.avif, https://cdn-assets.alltrails.com/assets/images/pro_signup/pro-map-weather-468w-${languageCode}.avif 2x`}
                        type="image/avif"
                      />
                      <source
                        srcSet={`https://cdn-assets.alltrails.com/assets/images/pro_signup/pro-map-weather-234w-${languageCode}.webp, https://cdn-assets.alltrails.com/assets/images/pro_signup/pro-map-weather-468w-${languageCode}.webp 2x`}
                        type="image/webp"
                      />
                      <img
                        alt="AllTrails weather map overlay"
                        data-map="weather"
                        className="devicemask__screen devicemask__screen--active"
                        loading="lazy"
                        src={`https://cdn-assets.alltrails.com/assets/images/pro_signup/pro-map-weather-234w-${languageCode}.jpg`}
                        width="234"
                      />
                    </picture>
                    <picture>
                      <source
                        srcSet={`https://cdn-assets.alltrails.com/assets/images/pro_signup/pro-map-airquality-234w-${languageCode}.avif, https://cdn-assets.alltrails.com/assets/images/pro_signup/pro-map-airquality-468w-${languageCode}.avif 2x`}
                        type="image/avif"
                      />
                      <source
                        srcSet={`https://cdn-assets.alltrails.com/assets/images/pro_signup/pro-map-airquality-234w-${languageCode}.webp, https://cdn-assets.alltrails.com/assets/images/pro_signup/pro-map-airquality-468w-${languageCode}.webp 2x`}
                        type="image/webp"
                      />
                      <img
                        alt="AllTrails air quality map overlay"
                        data-map="air-quality"
                        className="devicemask__screen"
                        loading="lazy"
                        src={`https://cdn-assets.alltrails.com/assets/images/pro_signup/pro-map-airquality-234w-${languageCode}.jpg`}
                        width="234"
                      />
                    </picture>
                    <picture>
                      <source
                        srcSet={`https://cdn-assets.alltrails.com/assets/images/pro_signup/pro-map-heatmaps-234w-${languageCode}.avif, https://cdn-assets.alltrails.com/assets/images/pro_signup/pro-map-heatmaps-468w-${languageCode}.avif 2x`}
                        type="image/avif"
                      />
                      <source
                        srcSet={`https://cdn-assets.alltrails.com/assets/images/pro_signup/pro-map-heatmaps-234w-${languageCode}.webp, https://cdn-assets.alltrails.com/assets/images/pro_signup/pro-map-heatmaps-468w-${languageCode}.webp 2x`}
                        type="image/webp"
                      />
                      <img
                        alt="AllTrails heatmap map overlay"
                        data-map="heatmap"
                        className="devicemask__screen"
                        loading="lazy"
                        src={`https://cdn-assets.alltrails.com/assets/images/pro_signup/pro-map-heatmaps-234w-${languageCode}.jpg`}
                        width="234"
                      />
                    </picture>
                    <picture>
                      <source
                        srcSet={`https://cdn-assets.alltrails.com/assets/images/pro_signup/pro-map-pollen-234w-${languageCode}.avif, https://cdn-assets.alltrails.com/assets/images/pro_signup/pro-map-pollen-468w-${languageCode}.avif 2x`}
                        type="image/avif"
                      />
                      <source
                        srcSet={`https://cdn-assets.alltrails.com/assets/images/pro_signup/pro-map-pollen-234w-${languageCode}.webp, https://cdn-assets.alltrails.com/assets/images/pro_signup/pro-map-pollen-468w-${languageCode}.webp 2x`}
                        type="image/webp"
                      />
                      <img
                        alt="AllTrails pollen map overlay"
                        data-map="pollen"
                        className="devicemask__screen"
                        loading="lazy"
                        src={`https://cdn-assets.alltrails.com/assets/images/pro_signup/pro-map-pollen-234w-${languageCode}.jpg`}
                        width="234"
                      />
                    </picture>
                    <picture>
                      <source
                        srcSet={`https://cdn-assets.alltrails.com/assets/images/pro_signup/pro-map-lightpollution-234w-${languageCode}.avif, https://cdn-assets.alltrails.com/assets/images/pro_signup/pro-map-lightpollution-468w-${languageCode}.avif 2x`}
                        type="image/avif"
                      />
                      <source
                        srcSet={`https://cdn-assets.alltrails.com/assets/images/pro_signup/pro-map-lightpollution-234w-${languageCode}.webp, https://cdn-assets.alltrails.com/assets/images/pro_signup/pro-map-lightpollution-468w-${languageCode}.webp 2x`}
                        type="image/webp"
                      />
                      <img
                        alt="AllTrails light pollution overlay"
                        data-map="light-pollution"
                        className="devicemask__screen"
                        loading="lazy"
                        src={`https://cdn-assets.alltrails.com/assets/images/pro_signup/pro-map-lightpollution-234w-${languageCode}.jpg`}
                        width="234"
                      />
                    </picture>
                  </div>
                </div>
              </div>

              <div className="maplist__wrapper">
                <ul className="maplist">
                  <li className="maplist__map">
                    <button className="maplist__button maplist__button--selected" data-map="weather">
                      <img
                        className="lazyload"
                        src="data:image/svg+xml,%3Csvg xmlns=&#39;http://www.w3.org/2000/svg&#39; viewBox=&#39;0 0 150 110&#39;%3E%3C/svg%3E"
                        data-src={mapWeather}
                        alt=""
                      />
                      <span className="maplist__maplabel">
                        <FormattedMessage defaultMessage="Weather" />
                      </span>
                    </button>
                  </li>
                  <li className="maplist__map">
                    <button className="maplist__button" data-map="air-quality">
                      <img
                        className="lazyload"
                        src="data:image/svg+xml,%3Csvg xmlns=&#39;http://www.w3.org/2000/svg&#39; viewBox=&#39;0 0 150 110&#39;%3E%3C/svg%3E"
                        data-src={mapAirQuality}
                        alt=""
                      />
                      <span className="maplist__maplabel">
                        <FormattedMessage defaultMessage="Air Quality" />
                      </span>
                    </button>
                  </li>
                  <li className="maplist__map">
                    <button className="maplist__button" data-map="heatmap">
                      <img
                        className="lazyload"
                        src="data:image/svg+xml,%3Csvg xmlns=&#39;http://www.w3.org/2000/svg&#39; viewBox=&#39;0 0 150 110&#39;%3E%3C/svg%3E"
                        data-src={mapHeatmap}
                        alt=""
                      />
                      <span className="maplist__maplabel">
                        <FormattedMessage defaultMessage="Heatmap" />
                      </span>
                    </button>
                  </li>
                  <li className="maplist__map">
                    <button className="maplist__button" data-map="pollen">
                      <img
                        className="lazyload"
                        src="data:image/svg+xml,%3Csvg xmlns=&#39;http://www.w3.org/2000/svg&#39; viewBox=&#39;0 0 150 110&#39;%3E%3C/svg%3E"
                        data-src={mapOverlayPollen}
                        alt=""
                      />
                      <span className="maplist__maplabel">
                        <FormattedMessage defaultMessage="Pollen" />
                      </span>
                    </button>
                  </li>
                  <li className="maplist__map">
                    <button className="maplist__button" data-map="light-pollution">
                      <img
                        className="lazyload"
                        src="data:image/svg+xml,%3Csvg xmlns=&#39;http://www.w3.org/2000/svg&#39; viewBox=&#39;0 0 150 110&#39;%3E%3C/svg%3E"
                        data-src={mapOverlayLightPollution}
                        alt=""
                      />
                      <span className="maplist__maplabel">
                        <FormattedMessage defaultMessage="Light Pollution" />
                      </span>
                    </button>
                  </li>
                </ul>
              </div>
              <div className="l-inner-container">
                <h3 className="feature__heading heading-3">
                  <FormattedMessage defaultMessage="Stay current with real-time map overlays." />
                </h3>
                <p>
                  <FormattedMessage defaultMessage="Know what to expect before you hit the trail. Plus users get access to real-time overlays including air quality, satellite weather, pollen, light pollution, and user heatmaps." />
                </p>
              </div>
            </div>
          </section>
          <section className="inlineboxes">
            <div className="l-container">
              <div className="boxes__container">
                <div className="box">
                  <div className="box__image">
                    <img
                      className="lazyload"
                      src="data:image/svg+xml,%3Csvg xmlns=&#39;http://www.w3.org/2000/svg&#39; viewBox=&#39;0 0 522 425&#39;%3E%3C/svg%3E"
                      data-src={printCustomMaps}
                      alt=""
                    />
                    <div className="box__icon">
                      <img
                        className="lazyload"
                        src="data:image/svg+xml,%3Csvg xmlns=&#39;http://www.w3.org/2000/svg&#39; viewBox=&#39;0 0 140 140&#39;%3E%3C/svg%3E"
                        data-src={iconPrint}
                        alt=""
                      />
                    </div>
                  </div>

                  <div className="box__content">
                    <h3 className="box__title heading-3">
                      <FormattedMessage defaultMessage="Print custom maps and always have a backup." />
                    </h3>
                    <p>
                      <FormattedMessage defaultMessage="No more thumbing through guide books. Print your own custom maps, choose your scale, orientation, gridlines, paper size, and even your map layer." />
                    </p>
                  </div>
                </div>
                <div className="box">
                  <div className="box__image">
                    <img
                      className="lazyload"
                      src="data:image/svg+xml,%3Csvg xmlns=&#39;http://www.w3.org/2000/svg&#39; viewBox=&#39;0 0 522 425&#39;%3E%3C/svg%3E"
                      data-src={noMoreAds}
                      alt=""
                    />
                    <div className="box__icon">
                      <img
                        className="lazyload"
                        src="data:image/svg+xml,%3Csvg xmlns=&#39;http://www.w3.org/2000/svg&#39; viewBox=&#39;0 0 140 140&#39;%3E%3C/svg%3E"
                        data-src={iconNoAds}
                        alt=""
                      />
                    </div>
                  </div>

                  <div className="box__content">
                    <h3 className="box__title heading-3">
                      <FormattedMessage defaultMessage="Cut out distractions and say goodbye to ads." />
                    </h3>
                    <p>
                      <FormattedMessage defaultMessage="Focus on finding the perfect trail not the advertisement. Go Plus and experience AllTrails ad-free. It’s that simple." />
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="textband textband--green">
            <div className="l-container">
              <div className="l-inner-container">
                <img
                  className="one_pct_logo feature__heading lazyload"
                  src="data:image/svg+xml,%3Csvg xmlns=&#39;http://www.w3.org/2000/svg&#39; viewBox=&#39;0 0 530 224&#39;%3E%3C/svg%3E"
                  data-src={onePercentLogo}
                  alt="1% For The Planet"
                />
                <p>
                  <FormattedMessage defaultMessage="As a proud member of 1% for the Planet, we give 1% of our annual sales to nonprofit organizations focusing on protecting the wild places we cherish and creating positive change for a healthier planet." />
                </p>
              </div>
              <p className="go-pro-cta">
                <a href="#header" className="t-button">
                  <FormattedMessage defaultMessage="Give back with Plus" />
                </a>
              </p>
            </div>
          </section>
        </div>
      </div>
    </CustomProvider>
  );
}
