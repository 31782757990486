import { FormattedMessage } from '@alltrails/shared/react-intl';
import Typography from '@alltrails/shared/denali/components/Typography';
import useLanguageRegionCode from '@alltrails/shared/hooks/useLanguageRegionCode';
import CurrencyCode from 'types/CurrencyCode';
import FormattedPercent from 'components/FormattedPercent';
import Plan from 'types/Plan';
import Coupon from 'types/Coupon';
import { getPriceTotal, isTrial } from 'utils/PlanUtils';
import * as styles from './PlanTypeCard.module.scss';

type Props = {
  plan: Plan;
  isActive?: boolean;
  currencyCode: CurrencyCode;
  isCouponApplied?: boolean;
  extraClasses?: string;
  coupon?: Coupon;
  billedAtOnce?: boolean;
};

// https://www.figma.com/file/gZaqAej8HzoFkyrjlQ4cNs/Integrating-Brand-Changes?node-id=586%3A30993
// https://www.figma.com/file/gZaqAej8HzoFkyrjlQ4cNs/Integrating-Brand-Changes?node-id=4500%3A91389
const PlanTypeCard = ({ billedAtOnce, plan, isActive = false, currencyCode, isCouponApplied = false, coupon, extraClasses }: Props) => {
  const languageRegionCode = useLanguageRegionCode();

  const formatter = new Intl.NumberFormat(languageRegionCode, {
    style: 'currency',
    currency: currencyCode
  });

  const formatterNoDecimals = new Intl.NumberFormat(languageRegionCode, {
    style: 'currency',
    currency: currencyCode,
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  });

  let { priceMonth, priceTotal, showDiscount } = plan;
  let discountAmount;

  if (isCouponApplied && coupon && plan.allowCoupon && coupon.type !== 'FreeTrialCoupon') {
    showDiscount = true;
    discountAmount = (
      <FormattedMessage
        defaultMessage="{percent} off"
        values={{
          percent: <FormattedPercent value={coupon.discountPercentage / 100} />
        }}
      />
    );
    priceTotal = getPriceTotal(plan, coupon);
    priceMonth = priceTotal / 12;
  }

  const perMonth = formatter.format(priceMonth);
  const total = formatter.format(priceTotal);

  function getPlanDetails() {
    if (billedAtOnce) {
      return {
        billedAt: <FormattedMessage defaultMessage="{perMonth}/month - billed once at {total}" values={{ perMonth, total }} />,
        label: <FormattedMessage defaultMessage="Annual plan" />
      };
    }

    if (isTrial(plan, coupon)) {
      return {
        billedAt: (
          <FormattedMessage
            defaultMessage="Then {perMonth}/month - billed annually at {total}. Pay {trialCost} today."
            values={{ perMonth, total, trialCost: formatterNoDecimals.format(0) }}
          />
        ),
        label: <FormattedMessage defaultMessage="First 7 days free" />
      };
    }

    return {
      billedAt: <FormattedMessage defaultMessage="{perMonth}/month - billed annually at {total}" values={{ perMonth, total }} />,
      label: <FormattedMessage defaultMessage="Annual plan" />
    };
  }

  const planDetail = getPlanDetails();

  if (!planDetail) {
    throw new Error(`No plan detail found for recurly plan ${plan.recurlyPlanCode}`);
  }

  let discount;
  let billedAtLang = planDetail.billedAt;

  if (showDiscount) {
    // billing language is slightly different when discounting first year
    discount = <div className={styles.discount}>{discountAmount}</div>;

    if (
      [
        'alltrails_pro_annual_10',
        'alltrails_pro_annual_20',
        'alltrails_pro_annual_21',
        'alltrails_pro_annual_24',
        'alltrails_pro_annual_30',
        'alltrails_pro_annual_36'
      ].includes(plan.recurlyPlanCode)
    ) {
      billedAtLang = <FormattedMessage defaultMessage="{perMonth}/month - 1st year billed at {total}" values={{ perMonth, total }} />;
    }
  }

  return (
    <div role="button" className={`${isActive ? styles.active : ''} ${styles.button} ${styles.card} ${extraClasses}`}>
      <div className={styles.contents}>
        <div className={styles.label}>
          <Typography component="div" variant="heading200">
            {planDetail.label}
          </Typography>
          {discount}
        </div>
        <Typography component="div" variant="text200">
          {billedAtLang}
        </Typography>
      </div>
    </div>
  );
};

export default PlanTypeCard;
