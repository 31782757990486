import { useRef } from 'react';
import type { ExperimentClient } from '@amplitude/experiment-js-client';
import Experiments from '@alltrails/shared/types/Experiments';
import useSelector from './useSelector';

const useExposureEvent = (flagKey: keyof Experiments) => {
  const experiments = useSelector(state => state.experiments);
  const experimentClient = useRef<ExperimentClient>();

  const setupExperimentClient = async () => {
    const amplitudeModule = await import('@amplitude/experiment-js-client');
    const { Experiment, Source } = amplitudeModule;
    experimentClient.current = Experiment.initializeWithAmplitudeAnalytics(__AT_DATA__.amplitudeDeploymentKey, {
      fetchOnStart: false,
      initialVariants: experiments,
      source: Source.InitialVariants
    });
  };

  const sendExposureEvent = async () => {
    if (!experiments) return;

    if (!experimentClient.current) {
      await setupExperimentClient();
    }

    if (experimentClient.current) {
      experimentClient.current.exposure(flagKey);
    }
  };

  return sendExposureEvent;
};

export default useExposureEvent;
