import { useState } from 'react';
import DropdownMenu from '@alltrails/shared/denali/components/DropdownMenu';
import ChevronSm from '@alltrails/shared/icons/ChevronSm';
import logWebHeaderHovered from '@alltrails/analytics/events/logWebHeaderHovered';
import * as styles from './styles/styles.module.scss';
import { HeaderSection, getHeaderLinkOnClickWithAnalytics } from './headerLinks';
import useHeaderLinks, { GetLinkInteractivity } from './useHeaderLinks';
import { HeaderBackground } from './types';

type DropdownProps = {
  background: HeaderBackground;
  getLinkInteractivity: GetLinkInteractivity;
  index: number;
  section: HeaderSection;
};

const Dropdown = ({ background, getLinkInteractivity, index, section }: DropdownProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const openDropdown = () => {
    logWebHeaderHovered({ web_header_position: index, web_header_section: section.webHeaderSection });
    setIsOpen(true);
  };

  const anchor = (
    <div className={styles.focusContainer}>
      <button className={styles.dropdownAnchor} onClick={openDropdown} data-testid={section.testId} type="button">
        {section.label}
        <ChevronSm
          color={background === 'transparentInverted' ? '--color-text-primary-inverse' : '--color-text-secondary'}
          orientation="down"
          size="sm"
        />
      </button>
    </div>
  );
  return (
    <DropdownMenu
      anchor={anchor}
      className={styles.dropdownMenu}
      containerMouseHandlers={{ onMouseEnter: openDropdown, onMouseLeave: () => setIsOpen(false) }}
      isOpen={isOpen}
      onCloseRequest={() => setIsOpen(false)}
      options={section.links.map((link, linkIndex) => {
        const { linkInfo, onClick } = getLinkInteractivity(link);
        const onClickWithAnalytics = getHeaderLinkOnClickWithAnalytics(linkIndex, link.webHeaderDropDownLink, section.webHeaderSection, () => {
          setIsOpen(false);
          onClick?.();
        });
        return {
          className: link.className,
          leadingIcon: link.leadingIcon,
          linkInfo,
          onClick: onClickWithAnalytics,
          testId: link.testId,
          title: link.title,
          trailingIcon: link.trailingIcon
        };
      })}
      placement="bottom-end"
      testId={`${section.testId}-dropdown`}
    />
  );
};

type DropdownsProps = {
  background: HeaderBackground;
};

const Dropdowns = ({ background }: DropdownsProps) => {
  const { headerLinks, getLinkInteractivity, signUpModal } = useHeaderLinks();

  return (
    <>
      <div className={styles.dropdowns}>
        {headerLinks.map((section, index) => (
          <Dropdown key={section.testId} background={background} getLinkInteractivity={getLinkInteractivity} index={index} section={section} />
        ))}
      </div>
      {signUpModal}
    </>
  );
};

export default Dropdowns;
