import { ReactNode } from 'react';
import { FormattedMessage } from '@alltrails/shared/react-intl';
import LanguageRegionCode from '@alltrails/shared/types/LanguageRegionCode';
import { wrapUrlSafe } from '@alltrails/shared/utils/languageSupport';
import PlaceTrail from '@alltrails/shared/icons/PlaceTrail';
import Community from '@alltrails/shared/icons/Community';
import NationalPark from '@alltrails/shared/icons/NationalPark';
import PlusBadge from '@alltrails/shared/denali/components/PlusBadge';
import Heart from '@alltrails/shared/icons/Heart';
import Shop from '@alltrails/shared/icons/Shop';
import ArrowExternal from '@alltrails/shared/icons/ArrowExternal';
import List from '@alltrails/shared/icons/List';
import Bookmark from '@alltrails/shared/icons/Bookmark';
import Settings from '@alltrails/shared/icons/Settings';
import Help from '@alltrails/shared/icons/Help';
import Logout from '@alltrails/shared/icons/Logout';
import Map from '@alltrails/shared/icons/Map';
import getHelpCenterUrl from '@alltrails/shared/utils/constants/helpCenterUrl';
import Chevron from '@alltrails/shared/icons/Chevron';
import Avatar from '@alltrails/shared/denali/components/Avatar';
import { fullName } from '@alltrails/shared/utils/formatters/textFormatters';
import { User } from 'types/User';
import { LinkInfo } from '@alltrails/shared/denali/components/DenaliComponentProps';
import { DropdownMenuOption, DropdownMenuTextOption } from '@alltrails/shared/types/dropdownMenu';
import hasPermission from 'utils/hasPermission';
import { Context } from 'types/Context';
import WebHeaderSection from '@alltrails/analytics/enums/WebHeaderSection';
import WebHeaderDropDownLink from '@alltrails/analytics/enums/WebHeaderDropDownLink';
import logWebHeaderDropDownTapped from '@alltrails/analytics/events/logWebHeaderDropDownTapped';
import * as styles from './styles/styles.module.scss';

type HeaderLinkBase = Pick<DropdownMenuTextOption, 'className' | 'leadingIcon' | 'onClick' | 'subtext' | 'title' | 'testId' | 'trailingIcon'> & {
  webHeaderDropDownLink: WebHeaderDropDownLink;
};

export type AuthRequiredHeaderLink = HeaderLinkBase & { requiresAuth: true; getLinkInfo: (user: User) => LinkInfo };

export type BasicHeaderLink = HeaderLinkBase & { requiresAuth?: never; linkInfo: LinkInfo };

export type HeaderLink = AuthRequiredHeaderLink | BasicHeaderLink;

export type HeaderSection = {
  label: ReactNode;
  links: HeaderLink[];
  testId: string;
  webHeaderSection: WebHeaderSection;
};

export const getHeaderLinks = (user: User | null, languageRegionCode: LanguageRegionCode): HeaderSection[] => {
  const exploreSection: HeaderSection = {
    label: <FormattedMessage defaultMessage="Explore" />,
    links: [
      {
        leadingIcon: { background: true, icon: { Component: PlaceTrail } },
        linkInfo: { href: wrapUrlSafe('/explore?ref=header', languageRegionCode) },
        testId: 'header-nearby-trails',
        title: <FormattedMessage defaultMessage="Nearby trails" />,
        webHeaderDropDownLink: WebHeaderDropDownLink.NearbyTrails
      },
      {
        leadingIcon: { background: true, icon: { Component: Community } },
        linkInfo: { href: wrapUrlSafe('/community?ref=header', languageRegionCode) },
        testId: 'header-community',
        title: <FormattedMessage defaultMessage="Community" />,
        webHeaderDropDownLink: WebHeaderDropDownLink.Community
      },
      {
        leadingIcon: { background: true, icon: { Component: NationalPark } },
        linkInfo: { href: wrapUrlSafe('/guides?ref=header', languageRegionCode) },
        testId: 'header-national-park-guides',
        title: <FormattedMessage defaultMessage="National park guides" />,
        webHeaderDropDownLink: WebHeaderDropDownLink.NationalParkGuides
      }
    ],
    testId: 'header-explore',
    webHeaderSection: WebHeaderSection.Explore
  };

  const savedLinks: HeaderLink[] = user
    ? [
        {
          leadingIcon: { background: true, icon: { Component: Bookmark } },
          linkInfo: { href: wrapUrlSafe(`/members/${user.slug}/favorites?ref=header`, languageRegionCode) },
          testId: 'header-favorites',
          title: <FormattedMessage defaultMessage="Favorites" />,
          webHeaderDropDownLink: WebHeaderDropDownLink.Favorites
        },
        {
          leadingIcon: { background: true, icon: { Component: Map } },
          linkInfo: { href: wrapUrlSafe(`/members/${user.slug}/maps?ref=header`, languageRegionCode) },
          testId: 'header-maps',
          title: <FormattedMessage defaultMessage="My maps" />,
          webHeaderDropDownLink: WebHeaderDropDownLink.MyMaps
        },
        {
          leadingIcon: { background: true, icon: { Component: List } },
          linkInfo: { href: wrapUrlSafe(`/members/${user.slug}/lists?ref=header`, languageRegionCode) },
          testId: 'header-lists',
          title: <FormattedMessage defaultMessage="Show all lists" />,
          webHeaderDropDownLink: WebHeaderDropDownLink.ViewAllLists
        }
      ]
    : [
        {
          leadingIcon: { background: true, icon: { Component: List } },
          testId: 'header-create-list',
          title: <FormattedMessage defaultMessage="Create a list" />,
          requiresAuth: true,
          getLinkInfo: ({ slug }) => ({
            href: wrapUrlSafe(`/members/${slug}/lists?ref=header`, languageRegionCode)
          }),
          webHeaderDropDownLink: WebHeaderDropDownLink.CreateAList
        },
        {
          leadingIcon: { background: true, icon: { Component: Map } },
          testId: 'header-create-map',
          title: <FormattedMessage defaultMessage="Create a map" />,
          requiresAuth: true,
          getLinkInfo: () => ({ href: wrapUrlSafe('/explore/map/new', languageRegionCode) }),
          webHeaderDropDownLink: WebHeaderDropDownLink.CreateAMap
        }
      ];
  const savedSection: HeaderSection = {
    label: <FormattedMessage defaultMessage="Saved" />,
    links: savedLinks,
    testId: 'header-saved',
    webHeaderSection: WebHeaderSection.Save
  };

  const shopLinks: HeaderLink[] = [];
  if (!user?.pro) {
    shopLinks.push({
      leadingIcon: { background: true, customElement: <PlusBadge size="md" /> },
      linkInfo: { href: wrapUrlSafe('/plus?ref=header', languageRegionCode) },
      testId: 'header-alltrails-plus',
      title: <FormattedMessage defaultMessage="AllTrails+" />,
      webHeaderDropDownLink: WebHeaderDropDownLink.AtPlus
    });
  }
  shopLinks.push(
    {
      leadingIcon: { background: true, icon: { Component: Heart } },
      linkInfo: { href: wrapUrlSafe('/gift?ref=header', languageRegionCode) },
      testId: 'header-give-alltrails-plus',
      title: <FormattedMessage defaultMessage="Give AllTrails+" />,
      webHeaderDropDownLink: WebHeaderDropDownLink.GiveAtPlus
    },
    {
      leadingIcon: { background: true, icon: { Component: Shop } },
      linkInfo: { href: 'https://shop.alltrails.com' },
      testId: 'header-shop',
      title: <FormattedMessage defaultMessage="Gear Shop" />,
      trailingIcon: { icon: { Component: ArrowExternal, color: '--color-text-secondary', size: 'xs' } },
      webHeaderDropDownLink: WebHeaderDropDownLink.GearShop
    }
  );
  const shopSection: HeaderSection = {
    label: <FormattedMessage defaultMessage="Shop" />,
    links: shopLinks,
    testId: 'header-shop',
    webHeaderSection: WebHeaderSection.Shop
  };

  return [exploreSection, savedSection, shopSection];
};

export const getUserLinks = (user: User, languageRegionCode: LanguageRegionCode, includeLeadingIcons = false): HeaderLink[] => {
  const links: HeaderLink[] = [];
  const fullUserName = fullName(user);
  links.push({
    leadingIcon: {
      customElement: <Avatar hasPlus={user.pro} size="sm" testId="header-show-profile-avatar" userId={user.id} userName={fullUserName} />
    },
    linkInfo: { href: wrapUrlSafe(`/members/${user.slug}?ref=header`, languageRegionCode) },
    subtext: <FormattedMessage defaultMessage="Show profile" />,
    testId: 'header-show-profile',
    title: fullUserName,
    trailingIcon: { icon: { Component: Chevron, orientation: 'right', size: 'sm' } },
    webHeaderDropDownLink: WebHeaderDropDownLink.ShowProfile
  });

  if (!user.pro) {
    links.push({
      className: styles.plusBackground,
      linkInfo: { href: wrapUrlSafe('/plus?ref=header', languageRegionCode) },
      testId: 'header-try-alltrails-plus',
      title: <FormattedMessage defaultMessage="Try AllTrails+ for free" />,
      trailingIcon: { customElement: <PlusBadge size="md" /> },
      webHeaderDropDownLink: WebHeaderDropDownLink.AtPlus
    });
  }

  links.push(
    {
      leadingIcon: includeLeadingIcons ? { icon: { Component: Settings }, background: true } : undefined,
      linkInfo: { href: wrapUrlSafe('/my/profile/edit?ref=header', languageRegionCode) },
      testId: 'header-settings',
      title: <FormattedMessage defaultMessage="Settings" />,
      webHeaderDropDownLink: WebHeaderDropDownLink.Settings
    },
    {
      leadingIcon: includeLeadingIcons ? { icon: { Component: Help }, background: true } : undefined,
      linkInfo: { href: getHelpCenterUrl(languageRegionCode) },
      testId: 'header-help-center',
      title: <FormattedMessage defaultMessage="Help center" />,
      webHeaderDropDownLink: WebHeaderDropDownLink.HelpCenter
    },
    {
      leadingIcon: includeLeadingIcons ? { icon: { Component: Logout }, background: true } : undefined,
      linkInfo: { href: wrapUrlSafe('/sign_out?ref=header', languageRegionCode) },
      testId: 'header-log-out',
      title: <FormattedMessage defaultMessage="Log out" />,
      webHeaderDropDownLink: WebHeaderDropDownLink.LogOut
    }
  );

  return links;
};

export const getUserPermissionLinks = (languageRegionCode: LanguageRegionCode, context: Context): DropdownMenuOption[] => {
  const adminHeaderSection: DropdownMenuOption = {
    sectionHeader: <FormattedMessage defaultMessage="Admin" />
  };

  const partnersHeaderSection: DropdownMenuOption = {
    sectionHeader: <FormattedMessage defaultMessage="Partners" />
  };

  const internalToolsLink: DropdownMenuOption = {
    linkInfo: { href: wrapUrlSafe('/manage/search', languageRegionCode) },
    testId: 'header-internal-tools',
    title: <FormattedMessage defaultMessage="Internal tools" />,
    trailingIcon: { icon: { Component: ArrowExternal, size: 'sm' } }
  };

  const publicLandsPortalLink: DropdownMenuOption = {
    linkInfo: { href: wrapUrlSafe('/portal', languageRegionCode) },
    testId: 'header-public-lands-portal',
    title: <FormattedMessage defaultMessage="Public Lands portal" />,
    trailingIcon: { icon: { Component: ArrowExternal, size: 'sm' } }
  };

  // Until the public_lands:manage role is launched, we need to use the legacy check to see if a user has portal access.
  // Since this is just a temporary patch, just cast the Context type here.
  const hasLegacyPortalAccess = ((context as Context & { groups?: any[] })?.groups?.length || 0) > 0;

  // In the future we may want 'portal:view' or a similar permission that we assign to certain roles
  if (
    hasPermission({ permission: 'areas:manage' }) ||
    hasPermission({ permission: 'locations:manage' }) ||
    hasPermission({ permission: 'public_lands_onboarding:manage' })
  ) {
    return [adminHeaderSection, internalToolsLink, publicLandsPortalLink];
  }
  if (hasPermission({ permission: 'guides:manage' }) || hasPermission({ permission: 'seo_tests:manage' })) {
    return [adminHeaderSection, internalToolsLink];
  }
  if (hasPermission({ permission: 'public_lands:manage' }) || hasLegacyPortalAccess) {
    return [partnersHeaderSection, publicLandsPortalLink];
  }

  return [];
};

export const getHeaderLinkOnClickWithAnalytics =
  (index: number, webHeaderDropDownLink: WebHeaderDropDownLink, webHeaderSection: WebHeaderSection, onClick?: () => void) => () => {
    logWebHeaderDropDownTapped({ item_name: webHeaderDropDownLink, item_position: index, web_header_section: webHeaderSection });
    onClick?.();
  };
