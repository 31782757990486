import logError from '../logError';
import { serverAjaxPromise } from './request_helpers';

// TODO: https://alltrails.atlassian.net/browse/CMTY-5883
const listAddRemoveAction = (method, url, params, errorMessage) =>
  serverAjaxPromise({
    type: method,
    url,
    data: params,
    resReducer: data => {
      if (data.errors && data.errors.length > 0) {
        logError(data.errors[0].message);
        return data.errors[0].message;
      } else if (data.listItems && data.listItems.length > 0) {
        return data.listItems[0];
      } else {
        logError(errorMessage);
        return errorMessage;
      }
    }
  });

const addToList = (listId, type, objectId, userId) => {
  const params = {
    object_id: objectId,
    type,
    include_obj: true
  };
  // Defaults request to updating own list unless a user id is specified
  if (userId) {
    params.user_id = userId;
  }
  return listAddRemoveAction('POST', `/api/alltrails/v2/lists/${listId}/items`, params, 'error adding item from the list');
};

const removeFromList = (listId, listItemId, userId) => {
  const params = {};
  // Defaults request to updating own list unless a user id is specified
  if (userId) {
    params.user_id = userId;
  }
  return listAddRemoveAction('DELETE', `/api/alltrails/v2/lists/${listId}/items/${listItemId}`, params, 'error removing item from the list');
};

const reorderList = (listId, orderedListItemIds) => {
  if (!orderedListItemIds || orderedListItemIds.length < 1) {
    return Promise.resolve([]);
  }
  return serverAjaxPromise({
    type: 'PUT',
    url: `/api/alltrails/v2/lists/${listId}/reorder`,
    data: {
      ordered_list_item_ids: orderedListItemIds
    },
    resReducer: data => data.listItems
  });
};

const copyList = listId => {
  return serverAjaxPromise({
    type: 'POST',
    url: `/api/alltrails/lists/${listId}/copy`,
    resReducer: data => data.lists[0]
  });
};

const updateListPrivacy = (listId, contentPrivacy) => {
  return serverAjaxPromise({
    type: 'PUT',
    url: `/api/alltrails/lists/${listId}`,
    data: {
      contentPrivacy
    },
    resReducer: data => {
      if (data.errors && data.errors.length > 0) {
        logError(data.errors[0].message);
        return data.errors[0].message;
      } else if (data.lists && data.lists.length > 0) {
        return data.lists[0];
      } else {
        logError('error updating the list');
        return 'error updating the list';
      }
    }
  });
};

export { addToList, removeFromList, reorderList, copyList, updateListPrivacy };
