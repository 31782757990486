import Modal from 'components/Modal';
import InitialSignUpScreen from 'components/InitialSignUpScreen';
import { MemoryRouter, Route, useHistory, useLocation } from '@alltrails/shared/components/ReactRouter';
import LogIn from 'components/LogIn';
import CreateAccount from 'components/CreateAccount';
import logWebSignupModalShown from '@alltrails/analytics/events/logWebSignUpModalShown';
import CarouselDisplayTrigger from '@alltrails/analytics/enums/CarouselDisplayTrigger';
import { ReactNode, useEffect } from 'react';
import type { User } from 'types/User';
import BranchInterstitial from 'components/DeepLink/BranchInterstitial';
import useDeeplinkComponent from 'components/DeepLink/hooks';
import InstallPromptLocation from '@alltrails/analytics/enums/InstallPromptLocation';

type ModalProps = {
  heading?: ReactNode;
  isOpen: boolean;
  onRequestClose: () => void;
  onSuccess: (user: User) => void;
  redirectUrl?: string;
};

type ModalContentProps = ModalProps & {
  trailId?: number;
  trigger?: CarouselDisplayTrigger;
};

type SignupModalProps = ModalProps & {
  showLogIn?: boolean;
  trigger?: CarouselDisplayTrigger;
  trailId?: number;
};

function Content({ heading, isOpen, onRequestClose, redirectUrl, onSuccess, trailId, trigger }: ModalContentProps) {
  const history = useHistory();
  const location = useLocation();
  const [isInterstitialOpen, setIsInterstitialOpen] = useDeeplinkComponent('interstitial', false);

  useEffect(() => {
    if (isOpen && !isInterstitialOpen) {
      setIsInterstitialOpen(true);
    }
  }, [isOpen]);

  const goBack = location.pathname !== '/' ? () => history.push('/') : undefined;

  return (
    <>
      <BranchInterstitial
        isOpen={isInterstitialOpen}
        onRequestClose={() => setIsInterstitialOpen(false)}
        pageId={trailId}
        promptLocation={InstallPromptLocation.TrailPage}
        displayTrigger={trigger}
      />
      <Modal goBack={goBack} isOpen={isOpen} onRequestClose={onRequestClose}>
        <Route path="/" exact>
          <InitialSignUpScreen heading={heading} onSuccess={onSuccess} redirectUrl={redirectUrl} />
        </Route>
        <Route path="/:locale?/login" exact>
          <LogIn heading={heading} onSuccess={onSuccess} redirectUrl={redirectUrl} />
        </Route>
        <Route path="/:locale?/register" exact>
          <CreateAccount heading={heading} onSuccess={onSuccess} redirectUrl={redirectUrl} />
        </Route>
      </Modal>
    </>
  );
}

export default function SignupModal({ heading, isOpen, onRequestClose, onSuccess, redirectUrl, showLogIn, trigger, trailId }: SignupModalProps) {
  useEffect(() => {
    if (isOpen) {
      logWebSignupModalShown({ trigger });
    }
  }, [isOpen, trigger]);

  if (!isOpen) {
    return null;
  }

  return (
    <MemoryRouter {...(showLogIn ? { initialEntries: ['/login'] } : {})}>
      <Content
        heading={heading}
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        onSuccess={onSuccess}
        redirectUrl={redirectUrl}
        trailId={trailId}
        trigger={trigger}
      />
    </MemoryRouter>
  );
}
