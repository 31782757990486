import { FormattedNumber } from '@alltrails/shared/react-intl';

type Props = {
  value: number;
};

function FormattedPercent({ value }: Props): JSX.Element {
  return (
    <FormattedNumber
      // eslint-disable-next-line react/style-prop-object
      style="percent"
      value={value}
    />
  );
}

export default FormattedPercent;
